import React from 'react';
import moment from 'moment-timezone';

import { Table } from '../../../components/elements';
import { useAuditUserData } from './helpers';

const durationColumnValue = (item, isActive) => {
  if (!item) {
    return;
  }
  let duration = '-';
  if (isActive === 'new' && item?.received?.created_at) {
    duration = moment(item?.received?.created_at).fromNow();
  } else if (isActive === 'confirmed' && item?.confirmed?.created_at) {
    duration = moment(item?.confirmed?.created_at).fromNow();
  } else if (isActive === 'waitlisted' && item?.waitlisted?.created_at) {
    duration = moment(item?.waitlisted?.created_at).fromNow();
  } else if (isActive === 'cancelled' && item?.cancelled?.created_at) {
    duration = moment(item?.cancelled?.created_at).fromNow();
  } else {
    duration = moment(item?.audit?.created_at).fromNow();
  }

  // eslint-disable-next-line consistent-return
  return duration;
};

const AuditUser = ({ userId }) => {
  const auditUserData = useAuditUserData({ userId });

  if (!auditUserData) {
    return <span>-</span>;
  }
  return <span>{auditUserData.email}</span>;
};

// const BookingUser = ({ userId }) => {
//   const bookingUserData = useAuditUserData({ userId });

//   if (!bookingUserData) {
//     return <span>-</span>;
//   }
//   return <span>{bookingUserData.display_name}</span>;
// };

const BookingActivityTable = ({
  loading,
  bookingActivityData,
  activeTab,
  history,
}) => {
  console.log('BookingActivityTable...');
  console.log(bookingActivityData);
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  <a>
                    <i
                    // className={
                    //   placeNameSort === 'AES'
                    //     ? 'fas fa-sort-amount-down'
                    //     : 'fas fa-sort-amount-up'
                    // }
                    />
                  </a>
                  Booking No
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Place Name</span>
              </div>
            </th>
            <th>
              <span>Place Phone</span>
            </th>
            <th>
              <span>City/Suburb</span>
            </th>
            <th>
              <span>Booking Date</span>
            </th>
            <th>
              <span>Booking Time</span>
            </th>
            <th>
              <span>Guests</span>
            </th>
            <th>
              <span>Table</span>
            </th>
            <th>
              <span>Guest Name</span>
            </th>
            <th>
              <span>Phone</span>
            </th>
            <th>
              <span>Email</span>
            </th>
            <th>
              <span>Duration</span>
            </th>
            <th>
              <span>Created By</span>
            </th>
            <th>
              <span>Device Info</span>
            </th>
            <th>
              <span>Booking Count</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {bookingActivityData.map(bad => {
            const placeMobileNumber =
              (
                (bad?.place_contact ?? []).find(
                  ele => ele.type === 'phone_primary',
                ) ?? {}
              ).value || null;

            // const userMobileNumber =
            //   (
            //     (bad?.contact ?? []).find(
            //       ele => ele.type === 'phone_primary',
            //     ) ?? {}
            //   ).value || null;

            return (
              <tr key={bad.table_booking_id}>
                <td
                  style={{
                    cursor: 'pointer',
                    color: '#00AEEF',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span
                    onClick={() => {
                      const newTabUrl = `/add-modify-booking/${bad.place_id}/${bad.table_booking_id}`;
                      history.push(newTabUrl);
                    }}
                  >
                    {bad.booking_reference}
                  </span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.place.name}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {placeMobileNumber ? (
                    <span>
                      <a href={`tel:${placeMobileNumber}`}>
                        {placeMobileNumber}
                      </a>
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.place.city}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.booking_date}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.booking_time}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.guest_count}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.table_number}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>
                    {bad.contact.first_name} {bad.contact.last_name}
                  </span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.contact.contact_phone}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.contact.contact_email}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{durationColumnValue(bad, activeTab)}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <AuditUser userId={bad.audit.created_by} />
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.app_type}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span>{bad.confirmed_count}</span>
                </td>
              </tr>
            );
          })}
          <></>
        </tbody>
      </Table>
      {loading && (
        <span
          style={{
            padding: '1rem',
            color: '#00AEEF',
            fontSize: '18px',
          }}
        >
          Loading...
        </span>
      )}
    </>
  );
};

export default BookingActivityTable;
