import React from 'react';
import { Column } from 'rbx';
import Input from '../../../components/elements/Input';
import Select from '../../../components/elements/Select';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const PrimaryDetailsForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <div>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <Input
          action={['create_place']}
          label="Place name"
          name="name"
          id="PlaceManagement_AddEditPlace_name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.name && touched.name && 'is-danger'}
          optionalText="(Max 75 Characters)"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={3}>
        <Select
          disabled
          action={['create_place']}
          label="Status"
          options={status}
          id="PlaceManagement_AddEditPlace_status"
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value)}
          errors={errors.status}
          touched={touched.status}
          required
        />
      </Column>
    </Column.Group>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={4}>
        <Input
          label="Pickup Url"
          name="foodPickupURL"
          value={values.foodPickupURL}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.foodPickupURL && touched.foodPickupURL && 'is-danger'
          }
          errors={errors.foodPickupURL}
          touched={touched.foodPickupURL}
        />
      </Column>
      <Column size={4}>
        <Input
          label="Delivery Url"
          name="foodDeliveryURL"
          value={values.foodDeliveryURL}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.foodDeliveryURL && touched.foodDeliveryURL && 'is-danger'
          }
          errors={errors.foodDeliveryURL}
          touched={touched.foodDeliveryURL}
        />
      </Column>
      <Column size={4}>
        <Input
          label="Table Booking Url"
          name="bookingURL"
          value={values.bookingURL}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.bookingURL && touched.bookingURL && 'is-danger'}
          errors={errors.bookingURL}
          touched={touched.bookingURL}
        />
      </Column>
    </Column.Group>
  </div>
);

export default PrimaryDetailsForm;
