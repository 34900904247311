import gql from 'graphql-tag';
import moment from 'moment';
import { forEach, groupBy, map } from 'lodash';

import client from '../../../utils/apolloClient';

export const parseLinkOverrideModifiersIntoGroups = modifiers => {
  const data = [];
  if (modifiers && modifiers.length !== 0) {
    const groups = groupBy(modifiers, 'modifier_group_id');
    map(groups, item => {
      data.push({
        modifier_group_id: item[0].modifier_group_id,
        modifiers: item,
      });
    });
  }
  return data;
};

export const finalModifierGroupModifiers = ({ modifiers, linkModifiers }) => {
  const data = [];
  forEach(modifiers, item => {
    const linkModifierItem = linkModifiers.filter(
      edge => edge.menu_item_id === item.menu_item.menu_item_id,
    );

    const newModifier = {
      menu_item_id: item.menu_item.menu_item_id,
      name: item.menu_item.name,
      availability_status: linkModifierItem[0].availability_status,
      availability_date: linkModifierItem[0].availability_date,
    };
    // if (linkModifierItem.length !== 0 && linkModifierItem[0].modifier_id) {
    //   newModifier.modifier_id = linkModifierItem[0].modifier_id;
    // } else {
    //   newModifier.modifier_id = uuid();
    // }
    // if (linkModifierItem.length !== 0 && linkModifierItem[0].override_price) {
    //   newModifier.price = linkModifierItem[0].override_price;
    // } else {
    //   newModifier.price = null;
    // }
    // if (
    //   linkModifierItem.length !== 0 &&
    //   linkModifierItem[0].availability_status &&
    //   item.menu_item.availability_status === 'AVAILABLE'
    // ) {
    //   newModifier.availability_status = linkModifierItem[0].availability_status;
    // } else {
    //   newModifier.availability_status = item.menu_item.availability_status;
    // }
    // if (
    //   linkModifierItem.length !== 0 &&
    //   linkModifierItem[0].un_available_dates &&
    //   linkModifierItem[0].un_available_dates !== 0
    // ) {
    //   newModifier.un_available_dates = linkModifierItem[0].un_available_dates;
    // } else {
    //   newModifier.un_available_dates = null;
    // }
    const temp = [];
    forEach(item.menu_item.variant, item2 => {
      const temp2 = [];

      const linkModifierItemVariant =
        linkModifierItem.length !== 0 &&
        linkModifierItem[0].override_variant &&
        linkModifierItem[0].override_variant.length !== 0
          ? linkModifierItem[0].override_variant.filter(
              edge3 => edge3.prompt_id === item2.prompt_id,
            )
          : [];
      forEach(item2.variants, item3 => {
        const linkModifierItemVariants =
          linkModifierItemVariant.length !== 0
            ? linkModifierItemVariant[0].variants.filter(
                edge3 => edge3.variant_id === item3.variant_id,
              )
            : [];

        const linkModifierItemPrice =
          linkModifierItemVariants.length !== 0
            ? linkModifierItemVariants[0].variant_price
            : null;

        const linkAvailabilityStatus =
          linkModifierItemVariants.length !== 0
            ? linkModifierItemVariants[0].availability_status
            : null;
        const linkAvailableDates =
          linkModifierItemVariants.length !== 0
            ? linkModifierItemVariants[0].availability_date
            : null;

        const newVariant = {
          variant_id: item3.variant_id,
          variant_name: item3.variant_name,
          variant_price: linkModifierItemPrice || null,
          availability_status: linkAvailabilityStatus,
          availability_date: linkAvailableDates,
        };
        temp2.push(newVariant);
      });
      temp.push({
        prompt_id: item2.prompt_id,
        prompt: item2.prompt,
        variants: temp2,
      });
      newModifier.variant = temp;
    });
    data.push(newModifier);
  });
  return data;
};

export const finalLinkListingItemDataParser = ({ link }) => {
  const data = {
    placeId: link.place_id,
    item_link_id: link.item_link_id,
    menu_item_id: link.menu_item.menu_item_id,
    name: link.menu_item.name,
    serviceType: link.service_type_setting
      ? link.service_type_setting.service_type
      : null,
    menuName: link.menu ? link.menu.name : null,
    rootItemAvailabilityStatus: link.menu_item
      ? link.menu_item.availability_status
      : null,
    availability_date:
      link.menu_item.availability_date || link.availability_date,
    sectionName: link.menu_section ? link.menu_section.name : null,
    modifier_groups_id: link.menu_item.modifier_group_id,
    availability_status: link.availability_status,
    originalModifier: link.modifier,
    readOnlyItem: !!link.menu_item.availability_date,
  };

  const temp = [];
  forEach(link.menu_item.variant, item => {
    const linkVariant = link.variant
      ? link.variant.filter(edge => edge.prompt_id === item.prompt_id)
      : [];
    const temp2 = [];
    forEach(item.variants, item2 => {
      const linkVariants =
        linkVariant.length !== 0
          ? linkVariant[0].variants.filter(
              edge2 => edge2.variant_id === item2.variant_id,
            )
          : [];
      const linkVariantPrice =
        linkVariants.length !== 0 ? linkVariants[0].variant_price : null;
      const linkAvailabilityStatus =
        linkVariants.length !== 0 ? linkVariants[0].availability_status : null;
      const linkAvailableDates =
        linkVariants.length !== 0 ? linkVariants[0].availability_date : null;
      const newVariant = {
        variant_id: item2.variant_id,
        variant_name: item2.variant_name,
        variant_price: linkVariantPrice || item2.variant_price,
        availability_status: linkAvailabilityStatus,
        availability_date: linkAvailableDates,
      };
      temp2.push(newVariant);
    });
    temp.push({
      prompt_id: item.prompt_id,
      prompt: item.prompt,
      variants: temp2,
    });
  });

  data.variant = temp;
  return data;
};

const searchLinksQuery = gql`
  query searchLinks($input: SearchInput) {
    search_availability_item_link(input: $input) {
      total_size
      total_pages
      availability_item_link_listing {
        item_link_id
        name
        description

        availability_date
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_name
            variant_price
            availability_date
          }
        }
        modifier {
          modifier_group_id
          menu_item_id
          override_variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_date
            }
          }

          availability_date
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_date
            }
          }
        }
        service_type_setting {
          service_type
        }
        menu {
          name
        }
        menu_section {
          menu_section_id
          name
        }
        menu_item {
          menu_item_id
          name
          price
          availability_date
          availability_status
          modifier_group_id
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
              availability_date
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getLinks = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchLinksQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_availability_item_link) {
          resolve(data.search_availability_item_link);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchMenuItemsQuery = gql`
  query searchMenuItems($input: SearchInput) {
    search_menu_items(input: $input) {
      menu_item_listing {
        name
        menu_item_id
        variant {
          prompt
          variants {
            variant_name
            variant_id
            availability_status
            availability_date
          }
        }
        modifier_groups {
          name
          modifier_group_id
          modifier {
            modifier_group_id
            availability_status
            availability_date
          }
        }
      }
    }
  }
`;

export const getMenuItem = ({ menuItemId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenuItemsQuery,
        variables: {
          input: {
            filter: {
              menu_item_filter: {
                menu_item_id: menuItemId,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_items &&
          data.search_menu_items.menu_item_listing
        ) {
          resolve(data.search_menu_items.menu_item_listing[0]);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchModifiersQuery = gql`
  query searchModifiers($input: SearchInput) {
    search_availability_modifier_group(input: $input) {
      total_size
      total_pages
      availability_modifier_group_listing {
        modifier_group_id
        name
        description
        modifier {
          menu_item {
            menu_item_id
            name
            status
            variant {
              prompt_id
              prompt
              variants {
                variant_id
                variant_name
                variant_price
                availability_status
                availability_date
              }
            }
          }
          modifier {
            availability_date
            override_variant {
              prompt_id
              prompt
              variants {
                variant_id
                variant_name
                variant_price
                availability_status
                availability_date
              }
            }
          }
        }
      }
    }
  }
`;

export const getModifiers = ({ userId, placeId, ids }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchModifiersQuery,
        variables: {
          input: {
            user_id: userId,
            sort: 'NAME_ASC',
            size: 20,
            from: 0,
            filter: {
              item_availability_filter: {
                place_id: placeId,
                modifier_group_id: ids,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_availability_modifier_group &&
          data.search_availability_modifier_group
            .availability_modifier_group_listing
        ) {
          resolve(
            data.search_availability_modifier_group
              .availability_modifier_group_listing,
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        menu_id
        name
        service_type
        service_type_setting {
          service_type_setting_id
        }
        hours
        stopped_hour {
          start
          end
        }
        menu_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
        holiday_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

export const getMenus = ({ userId, placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            user_id: userId,
            filter: {
              place_filter: {
                place_id: [placeId],
              },
              menu_filter: {
                status: 'ACTIVE',
                service_types: ['Pickup', 'Dinein', 'Delivery'],
                has_hours: true,
              },
              service_type_setting_filter: { status: 'ACTIVE' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menus && data.search_menus.menu_listing) {
          resolve(data.search_menus.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const updateItemLinkMutation = gql`
  mutation updateItemLink($input: [MenuItemLinkInput]) {
    update_menu_item_link(input: $input) {
      item_link_id
      service_type_setting_id
      menu_id
      menu_section_id
      menu_item_id
      price
      variant {
        prompt
        variants {
          availability_status
          variant_id
          variant_name
          variant_price
          availability_date
        }
      }
      availability_status
      availability_date
      display_order
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        code
        description
      }
    }
  }
`;

export const handleItemAvailabilityAction = ({
  linkId,
  userId,
  status,
  dates,
}) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: updateItemLinkMutation,
        variables: {
          input: {
            user_id: userId,
            item_link_id: linkId,
            availability_status: status,
            // un_available_dates: dates,
          },
        },
      })
      .then(({ data }) => {
        console.log('data', data);
        resolve(data);
        // if (
        //   data.search_menu_orders &&
        //   data.search_menu_orders.menu_order_listing
        // ) {
        //   resolve(data.search_menu_orders.menu_order_listing);
        // } else {
        //   resolve([]);
        // }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const handleVariantAvailabilityAction = ({ linkId, userId, variant }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: updateItemLinkMutation,
        variables: {
          input: {
            user_id: userId,
            item_link_id: linkId,
            variant,
          },
        },
      })
      .then(({ data }) => {
        console.log('data', data);
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const handleModifierAvailabilityAction = ({
  linkId,
  userId,
  modifier,
}) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: updateItemLinkMutation,
        variables: {
          input: {
            user_id: userId,
            item_link_id: linkId,
            modifier,
          },
        },
      })
      .then(({ data }) => {
        console.log('data', data);
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getRange = (startDate, endDate, type) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, type);
  const range = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < diff; i++) {
    range.push({
      start_date_time: moment()
        .add(i, type)
        .format(),
      end_date_time: moment(startDate)
        .add(i, type)
        .format(),
    });
  }
  return range;
};
