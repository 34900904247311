/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Container = styled.nav`
  padding: 0rem 1rem;
  .containerClassName {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    font-size: 16px;
  }
  .pageClassName {
    font-weight: 600;
    padding: 13px 1px;
  }
  .nextClassName {
    font-weight: 600;
  }
  .previousClassName {
    font-weight: 600;
  }
  .previousLinkClassName {
    padding: 14px 20px;
    color: #505050;
    font-weight: 600;
    font-size: 20px;
  }
  .nextLinkClassName {
    padding: 10px 20px;
    color: #505050;
    font-weight: 600;
    font-size: 20px;
  }
  .pageLinkClassName {
    padding: 10px 20px;
    color: #505050;
    :hover {
      background: #f6f6f6;
    }
    .activeClassName {
      background: #f6f6f6;
    }
  }
`;

// eslint-disable-next-line no-return-assign
const Pagination = ({
  pageCount,
  handlePageClick,
  pageFrom,
  listSize,
  currentPage,
  totalElements,
  hideLevelLeft = false,
}) => (
  <Container className="level">
    {!hideLevelLeft && (
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-4">
            {listSize > totalElements ? (
              <strong>{totalElements} row(s)</strong>
            ) : (
              <strong>
                {pageFrom + 1} to{' '}
                {currentPage === pageCount
                  ? totalElements
                  : pageFrom + listSize}{' '}
                of {totalElements} row(s) ({listSize} rows per page)
              </strong>
            )}
          </p>
        </div>
      </div>
    )}

    <div className="level-right">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next page"
        breakLabel="..."
        forcePage={currentPage}
        breakClassName="pagination-ellipsis"
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        // subContainerClassName="pages pagination"
        activeClassName="is-current"
        activeLinkClassName="is-current"
        // pageClassName="pagination-link"
        pageLinkClassName="pagination-link"
        previousLinkClassName="pagination-previous"
        nextLinkClassName="pagination-next"
      />
      &nbsp; {pageCount} Page(s)
    </div>
  </Container>
);

export default Pagination;
