import React from 'react';
import moment from 'moment';
import { Table, Box, Loading } from '../../../../components/elements';

const hourCol = hours => {
  if (!Array.isArray(hours.option)) {
    return '';
  }
  if (hours.option.length === 0) {
    return 'Closed all day';
  }
  if (
    hours.option[0].start === '12:00 AM' &&
    hours.option[0].end === '11:59 PM'
  ) {
    return 'Open 24 Hour';
  }

  return (
    hours.option.length !== 0 &&
    hours.option.map(ele => `${ele.start} - ${ele.end}`).join(' ')
  );
};

const List = ({ holidays, holidayLoading, onHandleUpdate }) => (
  <Box>
    <Table>
      <thead>
        <tr>
          <th>
            <div>
              <span>Date</span>
            </div>
          </th>

          <th>
            <div>
              <span>Hours</span>
            </div>
          </th>
          <th>
            <div>
              <span>Action</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {!holidayLoading &&
          holidays
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map(hours => (
              <tr key={hours.custom_hour_id}>
                <td>
                  <a onClick={() => onHandleUpdate(hours)}>
                    {moment(hours.date, 'YYYY-MM-DD').format('MMM, DD YYYY')}
                  </a>
                </td>
                <td
                  style={{
                    width: '150px',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {hourCol(hours)}
                </td>
                <td>-</td>
              </tr>
            ))}
      </tbody>
    </Table>

    {holidayLoading && <Loading />}
    {holidays.length === 0 && !holidayLoading && (
      <p style={{ textAlign: 'center' }}>No Data Found</p>
    )}
  </Box>
);
export default List;
