/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import tzLookup from 'tz-lookup';
import { filter } from 'lodash';
import { MdClose } from 'react-icons/md';

import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { ACLSConsumer } from '../../../utils/aclsContext';
import CancelModal from './CancelModal';
import AdjustPriceModal from './AdjustPriceModal';
import DelayOrderModal from './DelayOrderModal';
import OutOfStockModal from './OutOfStockModal';
import { handleOrderAction } from './helpers';
import client from '../../../utils/apolloClient';
import Information from './OrderDetailsModal/Information';
import Timing from './OrderDetailsModal/Timing';
import Options from './OrderDetailsModal/Options';
import SpecialInstruction from './OrderDetailsModal/SpecialInstruction';
import LineItems from './OrderDetailsModal/LineItems';
import Pricing from './OrderDetailsModal/Pricing';
import CardDetails from './OrderDetailsModal/CardDetails';
import ItemTotal from './OrderDetailsModal/ItemTotal';
import Cutlery from './OrderDetailsModal/Cutlery';
import CompleteStatus from './OrderDetailsModal/CompleteStatus';
import {
  NonDeliveryOrderTimeEstimates,
  DeliveryOrderTimeEstimates,
} from './TimeEstimatesModal';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      justify-content: center;
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
    .full-width {
      width: 100%;
    }
  }
`;

const AlterButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 30%;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.2rem;
  cursor: pointer;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 1rem;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const NewOrdersButton = styled.button`
  &&& {
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    background-color: ${({ color }) => color};
    font-weight: 600;
  }
`;

const SpecialSpacing = styled.div`
  padding: 0rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const calculateColor = status => {
  if (status === 'CONFIRMED') {
    return '#EE2A7B';
  }
  if (status === 'COMPLETE') {
    return 'green';
  }
  if (status === 'READY') {
    return '#FFC841';
  }
  if (status === 'CANCELLED') {
    return '#ee2a7b';
  }

  return '#00AEEF';
};

const searchUsers = gql`
  query searchUsers($userIdFromOrder: String) {
    search_users(
      input: { filter: { user_filter: { user_id: [$userIdFromOrder] } } }
    ) {
      user_listing {
        email
      }
    }
  }
`;
const searchUserOrdersLoyaltyCount = gql`
  query searchUserOrdersLoyaltyCount($userId: String, $placeId: String) {
    search_user_orders_loyalty_count(
      input: { user_id: $userId, place_id: $placeId }
    ) {
      loyalty_count
    }
  }
`;

const getCountText = count => 5 * Math.floor(count / 5);

const Form = props => {
  const {
    dueTimeToBeDisplayedInOrderDetailsModal = null,
    delayTimeToBeDisplayedInOrderDetailsModal = null,
    data: dataObj,
    receivedOrders = [],
    readyOrders = [],
    confirmedOrders = [],
    isActive,
    onClose,
    setReceivedOrders = () => {},
    setReadyOrders = () => {},
    addConfirmationOrderAfterReceived = () => {},
    addCompleteOrderAfterReady = () => {},
    setShowConfirmedTime = () => {},
    setConfirmedOrders = () => {},
    handleAfterDelayOrder = () => {},
    handleAfterAdjustment = () => {},
    orderComponent,
    refetchData = () => {},
  } = props;

  const lat = parseFloat(dataObj.place.latitude).toFixed(5);
  const long = parseFloat(dataObj.place.longitude).toFixed(5);
  const timeZone = tzLookup(lat, long);
  moment.tz.setDefault(timeZone);

  const { userId } = useStoreState(state => state.auth);

  const [order, setOrder] = useState(() => dataObj);

  const [isActiveCancelModal, setActiveCancelModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isActiveAdjustPriceModal, setActiveAdjustPriceModal] = useState(false);

  const [isActiveDelayOrderModal, setActiveDelayOrderModal] = useState(false);

  const [isActiveOutOfStockModal, setActiveOutOfStockModal] = useState(false);

  const [timeEstimateModal, setTimeEstimateModal] = useState(false);

  const [contactMail, setContactMail] = useState(null);

  const [loyalityCount, setLoyalityCount] = useState(null);

  const getConfirmedTime = () => {
    const currentTime = moment(new Date()).format('LT');
    setShowConfirmedTime(currentTime);
  };

  useEffect(() => {
    const userEmailId = (adminId, userIdFromOrder) =>
      new Promise((resolve, reject) => {
        client.clientPrivate
          .query({
            query: searchUsers,
            variables: {
              userIdFromOrder,
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            if (
              data.search_users &&
              data.search_users.user_listing &&
              data.search_users.user_listing[0]
            ) {
              resolve(data.search_users.user_listing[0].email);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('No Users Found');
            }
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    if (props.data && props.data.received && props.data.received.created_by) {
      userEmailId(userId, props.data.received.created_by).then(res =>
        setContactMail(res),
      );
    }
  }, [props.data]);

  useEffect(() => {
    const userOrdersLoyaltyCount = async (userIdFromOrder, placeId) => {
      try {
        const res = await client.clientPrivate.query({
          query: searchUserOrdersLoyaltyCount,
          variables: {
            userId: userIdFromOrder,
            placeId,
          },
          fetchPolicy: 'network-only',
        });
        if (res?.data?.search_user_orders_loyalty_count?.loyalty_count) {
          const l = parseInt(
            res.data.search_user_orders_loyalty_count.loyalty_count,
            10,
          );
          if (l) {
            setLoyalityCount(l);
          }
        }
        return null;
      } catch {
        return null;
      }
    };

    if (props.data && props.data.received && props.data.received.created_by) {
      userOrdersLoyaltyCount(
        props.data.received.created_by,
        props.data.place_id,
      );
    }
  }, [props.data]);

  const handleConfirmOrder = async id => {
    setIsLoading(true);
    try {
      const data = await handleOrderAction({
        menuId: id,
        userId,
        action: 'confirm',
      });

      setOrder(data);

      setIsLoading(false);
      // onClose();
      const newReceivedOrders = filter(
        receivedOrders,
        item => item.menu_order_id !== id,
      );
      getConfirmedTime();
      setReceivedOrders(newReceivedOrders);
      refetchData();

      if (!data.is_scheduled) {
        const shift = {
          ...data,
          menu: { name: data.menu_name },
          place: order.place,
        };
        addConfirmationOrderAfterReceived(shift);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const handleReadyOrder = async id => {
    setIsLoading(true);
    try {
      const data = await handleOrderAction({
        menuId: id,
        userId,
        action: 'prepare',
      });
      setOrder(data);
      setIsLoading(false);
      const newConfirmedOrders = confirmedOrders.filter(
        item => item.menu_order_id !== data.menu_order_id,
      );
      setConfirmedOrders(newConfirmedOrders);
      refetchData();
      // onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCompleteOrder = async id => {
    setIsLoading(true);
    try {
      const data = await handleOrderAction({
        menuId: id,
        userId,
        action: 'done',
        delay: null,
      });
      setOrder(data);
      const newReadyOrders = filter(
        readyOrders,
        item => item.menu_order_id !== data.menu_order_id,
      );
      setReadyOrders(newReadyOrders);
      refetchData();
      addCompleteOrderAfterReady({
        ...data,
        menu: { name: data.menu_name },
        place: order.place,
      });
      setIsLoading(false);
      // onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancelOrder = async (id, action, note) => {
    setIsLoading(true);
    try {
      await handleOrderAction({
        menuId: id,
        userId,
        action,
        note,
      });
      setIsLoading(false);
      onClose();
      if (order.status === 'RECEIVED') {
        const newReceivedOrders = filter(
          receivedOrders,
          item => item.menu_order_id !== id,
        );
        setReceivedOrders(newReceivedOrders);
      }
      if (order.status === 'CONFIRMED') {
        const newConfirmedOrders = filter(
          confirmedOrders,
          item => item.menu_order_id !== id,
        );
        setConfirmedOrders(newConfirmedOrders);
      }
    } catch (error) {
      const { description } = error[0] ?? {};
      if (description?.includes('Payment Lambda Error')) {
        swal({
          text: (description || '')
            .split('-')
            .filter((_, i) => i !== 0)
            .join(' ')
            .trim(),
        });
      } else if (description) {
        swal({ text: description });
      }
      setIsLoading(false);
    }
  };

  const handleAdjustOrderPrice = async (id, action, note, adjust_price) => {
    setIsLoading(true);
    try {
      const data = await handleOrderAction({
        menuId: id,
        userId,
        action,
        adjustments: {
          name: note.type,
          amount: -parseFloat(adjust_price),
          note: note.value,
        },
      });
      setOrder(data);
      handleAfterAdjustment(data);
      setIsLoading(false);
      // onClose();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleDelayOrder = async (id, action, note, delay) => {
    setIsLoading(true);
    try {
      const data = await handleOrderAction({
        menuId: id,
        userId,
        action,
        note,
        delay: parseInt(delay, 10),
        reason: note.value,
      });
      setOrder(data);
      handleAfterDelayOrder(data);
      setIsLoading(false);
      // onClose();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleOrder = id => {
    if (order.status === 'CONFIRMED') {
      handleReadyOrder(id);
    }
    if (order.status === 'RECEIVED') {
      handleConfirmOrder(id);
    }
    if (order.status === 'READY') {
      handleCompleteOrder(id);
    }
  };

  const handleTimeLineTime = status => {
    const getStatus = () => {
      if (status === 'RECEIVED') {
        return 'received';
      }
      if (status === 'CONFIRMED') {
        return 'confirmed';
      }
      if (status === 'READY') {
        return 'ready';
      }
      if (status === 'COMPLETE') {
        return 'complete';
      }
      if (status === 'CANCELLED') {
        return 'cancelled';
      }
      return 'received';
    };
    const orderStatus = order[getStatus()];
    const time = orderStatus ? orderStatus.created_at : null;
    return time;
  };

  const handleStatus = type => {
    if (order.status === 'RECEIVED') {
      return 'New Order';
    }
    if (order.status === 'CONFIRMED') {
      return 'Confirmed';
    }
    if (order.status === 'READY') {
      if (type === 'Delivery') {
        return 'Order on its way';
      }
      if (type === 'Dinein') {
        return 'Serve Order';
      }
      return 'Ready';
    }
    if (order.status === 'CANCELLED') {
      return 'CANCELLED';
    }
    if (order.status === 'COMPLETE') {
      if (order.service_type === 'Pickup') {
        return 'Picked Up';
      }
      if (order.service_type === 'Dinein') {
        return 'Served';
      }
      if (order.service_type === 'Delivery') {
        return 'Delivered';
      }
    }
    return null;
  };

  const showReadyStatus = () => {
    if (order.service_type === 'Pickup') {
      return 'MARK ORDER AS PICKEDUP';
    }
    if (order.service_type === 'Dinein') {
      return 'MARK ORDER AS SERVED';
    }
    if (order.service_type === 'Delivery') {
      return 'MARK ORDER AS DELIVERED';
    }
    return null;
  };

  const showConfirmStatus = () => {
    if (order.service_type === 'Pickup') {
      return 'CONFIRM ORDER AS READY';
    }
    if (order.service_type === 'Dinein') {
      return 'READY TO SERVE ORDER';
    }
    if (order.service_type === 'Delivery') {
      return 'CONFIRM ORDER ON ITS WAY';
    }
    return null;
  };

  const trackPath = () => {
    if (order.service_type === 'Pickup') {
      return 'Pickedup';
    }
    if (order.service_type === 'Dinein') {
      return 'Served';
    }
    if (order.service_type === 'Delivery') {
      return 'Delivered';
    }
    return null;
  };

  const notesArr = order.received;
  const notes = notesArr ? notesArr.note : null;
  const receivedStatusHistory = order.received;

  const receivedTime = receivedStatusHistory
    ? receivedStatusHistory.created_at
    : null;

  const cancelledBy = () => {
    const orderStatus = order.cancelled;

    const user = orderStatus ? orderStatus.user_type : null;
    const time = orderStatus
      ? moment(orderStatus.created_at).format('LLL')
      : null;
    return { user, time };
  };

  const disableCancelButton = (orderStatus, isSuperAdmin) => {
    let isDisable = false;
    if (orderStatus === 'COMPLETE') {
      isDisable = true;
    } else if (orderStatus === 'CONFIRMED' && isSuperAdmin) {
      isDisable = false;
    } else if (orderStatus === 'CONFIRMED') {
      isDisable = true;
    } else if (orderStatus === 'READY' && isSuperAdmin) {
      isDisable = false;
    } else if (orderStatus === 'READY') {
      isDisable = true;
    }
    return isDisable;
  };

  return (
    <ACLSConsumer>
      {({ role }) => {
        const isSuperAdmin =
          role.filter(rl => rl === 'kravein_super_admin').length !== 0;
        return (
          <>
            <Container
              className={`modal ${isActive && 'is-active'}`}
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <div className="modal-background" />
              <div className="modal-card">
                <header className="modal-card-head">
                  <CloseButtonWrapper>
                    <MdClose
                      size={30}
                      type="button"
                      onClick={() => {
                        onClose();
                      }}
                    />
                  </CloseButtonWrapper>
                  <HeaderContent>
                    <p className="modal-card-title is-uppercase">
                      {order.service_type}
                    </p>
                    {order.is_scheduled &&
                    Date.now() <
                      new Date(order.scheduled_date_time).getTime() ? (
                      <p
                        className="is-size-4"
                        style={{
                          position: 'relative',
                          right: '25px',
                          marginTop: '6px',
                        }}
                      >
                        Scheduled&nbsp;
                        {moment(order.scheduled_date_time).format(
                          'DD/MM HH:mm A',
                        )}
                      </p>
                    ) : (
                      (dueTimeToBeDisplayedInOrderDetailsModal ||
                        delayTimeToBeDisplayedInOrderDetailsModal) && (
                        <p className="is-size-4" style={{ paddingTop: '2px' }}>
                          {dueTimeToBeDisplayedInOrderDetailsModal ||
                            delayTimeToBeDisplayedInOrderDetailsModal}
                        </p>
                      )
                    )}
                  </HeaderContent>

                  <NewOrdersButton
                    type="button"
                    className="button is-primary is-uppercase"
                    color={calculateColor(order.status)}
                  >
                    {handleStatus(order.service_type)}
                  </NewOrdersButton>
                </header>

                <section className="modal-card-body">
                  <DetailsFormWrapper>
                    <>
                      {order?.place?.name && (
                        <p
                          className="is-size-3 "
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {`${order.place.name}`}
                        </p>
                      )}
                      {!!loyalityCount && loyalityCount > 5 && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            type="button"
                            className="button is-primary is-uppercase is-rounded"
                            style={{
                              backgroundColor: '#ee2a7b',
                            }}
                          >
                            LOYAL CUSTOMER {getCountText(loyalityCount)}+
                          </button>
                        </div>
                      )}
                      {order.status === 'CANCELLED' && (
                        <>
                          <p className="is-size-3">
                            Cancelled on {cancelledBy().time}
                          </p>
                          <p className="is-size-3">
                            Cancelled by{' '}
                            {cancelledBy().user === 'USER'
                              ? 'Customer'
                              : cancelledBy().user === 'PARTNER'
                              ? order.place.name
                              : 'KRAVEIN'}{' '}
                            -{' '}
                            {`${order.cancelled.user_data.first_name} ${order.cancelled.user_data.last_name} (${order.cancelled.user_data.email})`}
                          </p>
                          {order?.cancelled?.note?.type &&
                            order?.cancelled?.note?.value && (
                              <p className="is-size-3">
                                Reason -&nbsp;
                                {`${order?.cancelled?.note?.value} (${order?.cancelled?.note?.type})`}
                              </p>
                            )}
                          <br />
                        </>
                      )}
                      {order.service_type === 'Dinein' && order.table_number && (
                        <>
                          <p className="is-size-1 has-text-weight-bold ">
                            {order.table_name || 'Table'} No -{' '}
                            {order.table_number}
                          </p>
                        </>
                      )}

                      <br />
                      <Information order={order} contactMail={contactMail} />
                      <Timing order={order} receivedTime={receivedTime} />
                      {order.service_type === 'Pickup' &&
                        (order.pickup_method || order.vehicle_information) && (
                          <Options order={order} />
                        )}
                      {order.service_type === 'Dinein' &&
                        (order.no_of_attendees || order.table_number) && (
                          <Options order={order} />
                        )}
                      {order.service_type !== 'Pickup' &&
                        order.service_type !== 'Dinein' && (
                          <Options order={order} />
                        )}
                      <SpecialInstruction notes={notes} />
                    </>

                    {order.status !== 'CANCELLED' &&
                      orderComponent === 'Orders' && (
                        <SpecialSpacing>
                          <AlterButton
                            type="button"
                            disabled={disableCancelButton(
                              order.status,
                              isSuperAdmin,
                            )}
                            className="button control"
                            onClick={() => setActiveCancelModal(true)}
                          >
                            Cancel
                          </AlterButton>

                          <AlterButton
                            type="button"
                            className="button control"
                            disabled={
                              order.status === 'READY' ||
                              order.status === 'RECEIVED' ||
                              order.status === 'COMPLETE'
                            }
                            onClick={() => setActiveDelayOrderModal(true)}
                          >
                            Delay Order
                          </AlterButton>

                          <AlterButton
                            type="button"
                            className="button control"
                            disabled={
                              order.status === 'RECEIVED' ||
                              order.status === 'COMPLETE'
                            }
                            onClick={() => setActiveAdjustPriceModal(true)}
                          >
                            Adjust Price
                          </AlterButton>
                        </SpecialSpacing>
                      )}
                    <br />
                    <p className="is-size-3 has-text-weight-bold">
                      Order details
                    </p>
                    <br />
                    <LineItems order={order} />
                    <ItemTotal order={order} />
                    <Pricing order={order} />
                    <CardDetails order={order} />
                    <Cutlery order={order} />

                    <CompleteStatus
                      order={order}
                      handleTimeLineTime={handleTimeLineTime}
                      trackPath={trackPath}
                    />
                  </DetailsFormWrapper>
                </section>
                {timeEstimateModal && order.service_type === 'Delivery' ? (
                  <DeliveryOrderTimeEstimates
                    isActive={timeEstimateModal}
                    setTimeEstimateModal={setTimeEstimateModal}
                    menuOrderId={order.menu_order_id}
                    estimates={order?.estimates ?? []}
                  />
                ) : (
                  <NonDeliveryOrderTimeEstimates
                    isActive={timeEstimateModal}
                    setTimeEstimateModal={setTimeEstimateModal}
                    menuOrderId={order.menu_order_id}
                    estimates={order?.estimates ?? []}
                  />
                )}

                {order.status === 'COMPLETE' &&
                  order.payment.payment_method.toUpperCase() === 'CARD' && (
                    <footer
                      className="modal-card-foot"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        className={`button is-large is-fullwidth is-primary ${isLoading &&
                          'is-loading'}`}
                        onClick={() => {
                          window.location.href = `/refund/${order.menu_order_id}`;
                        }}
                      >
                        REFUND ORDER
                      </button>
                    </footer>
                  )}

                {order.status !== 'CANCELLED' &&
                  order.status !== 'COMPLETE' &&
                  order.status === 'RECEIVED' && (
                    <footer
                      className="modal-card-foot"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        className="button is-large is-fullwidth is-primary"
                        onClick={() => setTimeEstimateModal(true)}
                      >
                        CHANGE TIME
                      </button>
                      <button
                        className={`button is-large is-fullwidth is-primary ${isLoading &&
                          'is-loading'}`}
                        onClick={() => handleOrder(order.menu_order_id)}
                      >
                        CONFIRM ORDER
                      </button>
                    </footer>
                  )}
                {order.status !== 'CANCELLED' &&
                  order.status !== 'COMPLETE' &&
                  order.status !== 'RECEIVED' && (
                    <footer className="modal-card-foot">
                      <button
                        className={`button is-large is-fullwidth is-primary ${isLoading &&
                          'is-loading'}`}
                        onClick={() => handleOrder(order.menu_order_id)}
                      >
                        {order.status === 'CONFIRMED' && showConfirmStatus()}
                        {order.status === 'READY' && showReadyStatus()}
                      </button>
                    </footer>
                  )}
              </div>
            </Container>
            {isActiveCancelModal && (
              <CancelModal
                order={order}
                handleCancelOrder={handleCancelOrder}
                values={null}
                isActiveCancelModal={isActiveCancelModal}
                setActiveCancelModal={setActiveCancelModal}
              />
            )}
            {isActiveAdjustPriceModal && (
              <AdjustPriceModal
                order={order}
                handleAdjustOrderPrice={handleAdjustOrderPrice}
                isActiveAdjustPriceModal={isActiveAdjustPriceModal}
                setActiveAdjustPriceModal={setActiveAdjustPriceModal}
              />
            )}
            {isActiveDelayOrderModal && (
              <DelayOrderModal
                order={order}
                handleDelayOrder={handleDelayOrder}
                isActiveDelayOrderModal={isActiveDelayOrderModal}
                setActiveDelayOrderModal={setActiveDelayOrderModal}
              />
            )}

            {isActiveOutOfStockModal && (
              <OutOfStockModal
                order={order}
                isActiveOutOfStockModal={isActiveOutOfStockModal}
                setActiveOutOfStockModal={setActiveOutOfStockModal}
              />
            )}
          </>
        );
      }}
    </ACLSConsumer>
  );
};

export default Form;
