import React, { useEffect, useState, useReducer, useCallback } from 'react';
import { useStoreState } from 'easy-peasy';
import Loading from 'react-loading';
import { forEach, isNull } from 'lodash';
import { toast } from 'react-toastify';
import uuid from 'uuid';

import client from '../../../../utils/apolloClient';

import AddCategoryModal from './AddCategoryModal';
import CategoriesSection from './CategoriesSection';
import MenuDesignerHeader from './MenuDesignerHeader';
import SearchItemModal from '../../Section/SearchItemModal';
import ItemDetailsSection from './ItemDetailsSection';
import ItemDetailsForm from './ItemDetailsForm';

import {
  itemsOfInitialActiveCategory,
  getLinks,
  transformLinks,
} from './CategoriesSection/helper';
import { getAllCategories, createItemLinkMutation } from './helpers';

const editsReducer = (state, action) => {
  switch (action.type) {
    case 'START_EDIT_CATEGORIES':
      return { ...state, editCategories: true, editMenuItems: false };
    case 'STOP_EDIT_CATEGORIES':
      return { ...state, editCategories: false };
    case 'START_EDIT_MENU_ITEMS':
      return { ...state, editCategories: false, editMenuItems: true };
    case 'STOP_EDIT_MENU_ITEMS':
      return { ...state, editMenuItems: false };
    default:
      return { ...state };
  }
};

const MenuDesigner = ({ menuId, placeId, settingId, placeLocation }) => {
  const { userId } = useStoreState(state => state.auth);

  const [activeCategory, setActiveCategory] = useState(null);

  const [categories, setCategories] = useState([]);

  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);

  const [addCategoryModalIsActive, setAddCategoryModalIsActive] = useState(
    false,
  );

  const [itemsOfActiveCategory, setItemsOfActiveCategory] = useState([]);

  const [itemIsLoading, setItemIsLoading] = useState(false);

  const [addItemModalIsActive, setAddItemModalIsActive] = useState(false);

  const [tempItems, setTempItems] = useState([]);

  const [itemDetailsFormData, setItemDetailsFormData] = useState(null);

  const [itemDetailsFormDataLoading, setItemDetailsFormDataLoading] = useState(
    false,
  );

  const [itemLinkLoading, setItemLinkLoading] = useState(false);

  const [itemRef, setItemRef] = useState({});

  const [edits, editsDispatch] = useReducer(editsReducer, {
    editCategories: false,
    editMenuItems: false,
  });

  const createItemLink = async inputs => {
    setItemLinkLoading(true);
    try {
      const response = await client.clientPrivate.mutate({
        client: client.clientPrivate,
        mutation: createItemLinkMutation,
        variables: {
          input: inputs,
        },
      });
      forEach(response.data.create_menu_item_link, item => {
        if (!isNull(item.error)) {
          item.error.map(item2 => toast.error(item2.description));
        } else {
          setAddItemModalIsActive(false);
          setItemsOfActiveCategory(itemsOfActiveCategory.concat(tempItems));
          setTempItems([]);
        }
      });
      setItemLinkLoading(false);
    } catch (error) {
      console.log(error);
      setItemLinkLoading(false);
    }
  };

  const fetchCategories = useCallback(async () => {
    setCategoriesIsLoading(true);
    try {
      const response = await getAllCategories({
        place_id: placeId,
        menu_id: menuId,
        user_id: userId,
      });
      if (response && response[0]) {
        setActiveCategory(response[0].menu_section_id);
        itemsOfInitialActiveCategory({
          setItemIsLoading,
          setItemsOfActiveCategory,
          menuId,
          setItemDetailsFormData,
          categoryId: response[0].menu_section_id,
        });
        setCategories(response);
        setCategoriesIsLoading(false);
      } else {
        setCategories([]);
        setCategoriesIsLoading(false);
      }
    } catch {
      setCategories([]);
      setCategoriesIsLoading(false);
    }
  }, [placeId, menuId, userId]);

  const refreshItemCards = async () => {
    setItemIsLoading(true);
    setTimeout(async () => {
      try {
        let links = await getLinks({ menuId });
        links =
          Array.isArray(links) && links.length !== 0
            ? links.filter(
                lnk => lnk.menu_section.menu_section_id === activeCategory,
              )
            : [];
        if (links.length !== 0) {
          setItemsOfActiveCategory(transformLinks(links));
          setItemIsLoading(false);
          setItemRef({});
        } else {
          setItemsOfActiveCategory([]);
          setItemIsLoading(false);
          setItemRef({});
        }
      } catch (error) {
        console.log(error);
        setItemIsLoading(false);
      }
    }, [1000]);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    setItemDetailsFormDataLoading(false);
    setItemDetailsFormData(null);
  }, [activeCategory]);

  return (
    <>
      {addItemModalIsActive && (
        <SearchItemModal
          placeId={placeId}
          isCreateLinkLoading={itemLinkLoading}
          initialsItems={itemsOfActiveCategory}
          isActive={addItemModalIsActive}
          onClose={() => setAddItemModalIsActive(false)}
          onSubmit={async values => {
            const inputs = values.map(item => {
              const tempVariant = [];

              if (Array.isArray(item.variant) && item.variant.length !== 0) {
                item.variant.forEach(ele => {
                  const temp2 = {
                    prompt_id: ele.prompt_id,
                    prompt: ele.prompt,
                  };
                  const variants = [];
                  if (
                    Array.isArray(ele.variants) &&
                    ele.variants.length !== 0
                  ) {
                    ele.variants.forEach(ele2 => {
                      variants.push({
                        variant_id: ele2.variant_id,
                        variant_name: ele2.variant_name,
                        variant_price: null,
                        availability_status: ele2.availability_status,
                        deliverect_plu_id: ele2.deliverect_plu_id,
                      });
                    });
                  }
                  temp2.variants = variants;
                  tempVariant.push(temp2);
                });
              }

              return {
                user_id: userId,
                service_type_setting_id: settingId,
                menu_id: menuId,
                menu_section_id: activeCategory,
                menu_item_id: item.menu_item_id,
                item_link_id: uuid(),
                place_id: item.place_id,
                price: null,
                variant: tempVariant,
                // availability_status: item.availability_status,
              };
            });
            setTempItems(values);
            await createItemLink(inputs);
            refreshItemCards();
          }}
        />
      )}

      <AddCategoryModal
        addCategoryModalIsActive={addCategoryModalIsActive}
        setAddCategoryModalIsActive={setAddCategoryModalIsActive}
        userId={userId}
        placeId={placeId}
        fetchCategories={fetchCategories}
        currentMenuCategories={categories}
        menuId={menuId}
      />

      <MenuDesignerHeader
        setAddCategoryModalIsActive={setAddCategoryModalIsActive}
        setAddItemModalIsActive={setAddItemModalIsActive}
        edits={edits}
        editsDispatch={editsDispatch}
      />

      <div style={{ display: 'flex' }}>
        <CategoriesSection
          setActiveCategory={setActiveCategory}
          setItemsOfActiveCategory={setItemsOfActiveCategory}
          setItemIsLoading={setItemIsLoading}
          categories={categories}
          categoriesIsLoading={categoriesIsLoading}
          activeCategory={activeCategory}
          location={placeLocation}
          menuId={menuId}
          placeId={placeId}
          setItemDetailsFormData={setItemDetailsFormData}
          setCategories={setCategories}
          editCategories={edits.editCategories}
          editsDispatch={editsDispatch}
        />
        <div style={{ paddingLeft: '12px', position: 'relative' }}>
          {itemIsLoading ? (
            <div style={{ position: 'absolute', padding: '2rem 0 0 8rem' }}>
              <Loading type="spin" color="#363636" />
            </div>
          ) : (
            <ItemDetailsSection
              itemDetailsFormData={itemDetailsFormData}
              itemsOfActiveCategory={itemsOfActiveCategory}
              setItemsOfActiveCategory={setItemsOfActiveCategory}
              setItemDetailsFormData={setItemDetailsFormData}
              activeCategory={activeCategory}
              userId={userId}
              menuId={menuId}
              setItemDetailsFormDataLoading={setItemDetailsFormDataLoading}
              editMenuItems={edits.editMenuItems}
              editsDispatch={editsDispatch}
              placeId={placeId}
              itemRef={itemRef}
              setItemRef={setItemRef}
            />
          )}
        </div>
        <div style={{ position: 'relative' }}>
          {!itemIsLoading && !!itemDetailsFormDataLoading && (
            <div style={{ padding: '2rem 0 0 8rem', position: 'absolute' }}>
              <Loading type="spin" color="#363636" />
            </div>
          )}
          {itemsOfActiveCategory.length !== 0 &&
            Array.isArray(itemDetailsFormData) &&
            itemDetailsFormData.length !== 0 && (
              <ItemDetailsForm
                setItemDetailsFormData={setItemDetailsFormData}
                activeCategory={activeCategory}
                menuId={menuId}
                setItemDetailsFormDataLoading={setItemDetailsFormDataLoading}
                itemDetailsFormData={itemDetailsFormData}
                refreshItemCards={refreshItemCards}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default MenuDesigner;
