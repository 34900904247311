import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <>
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Title className="is-size-2">Manage Table Booking Policies</Title>
        </Level.Item>
      </Level>
    </Wrapper>
  </>
);

export default Header;
