import React from 'react';
import PrimaryDetailsForm from './PrimaryDetailsForm';
import LocationForm from './LocationForm';
import Contacts from './Contacts';

const PlaceInformation = ({ props }) => (
  <div>
    <PrimaryDetailsForm {...props} />
    <br />
    <LocationForm action={['create_place']} {...props} />
    <br />
    <Contacts action={['update_place', 'create_place']} {...props} />
  </div>
);
export default PlaceInformation;
