/* eslint-disable func-names */
import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../../components/elements';
import UserSuggestions from '../../../../components/global/UserSuggestions';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 80vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const Form = props => {
  const {
    isActive,
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    // touched,
    // errors,
  } = props;

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Admin</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <Column.Group gapSize={8} multiline vcentered>
            <Column size="half">
              <UserSuggestions
                initialValue=""
                label="Search For Admins"
                onSelect={data => {
                  setFieldValue('admin_user', data);
                }}
                onHandleClear={() => {
                  setFieldValue('admin_user', null);
                }}
              />
            </Column>

            <Column size="half">
              <Input
                label="Admin Details"
                value={
                  values.admin_user
                    ? `${values.admin_user.display_name} - ${values.admin_user.email}`
                    : ''
                }
              />
            </Column>
          </Column.Group>
        </section>

        <footer className="modal-card-foot">
          <button type="button" className="button" onClick={onClose}>
            cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </footer>
      </div>
    </Container>
  );
};

const AddEditForm = withFormik({
  mapPropsToValues: () => ({
    admin_user: null,
  }),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props }) => {
    props.onSubmit({
      admin_user: values.admin_user,
    });
  },
  enableReinitialize: true,
  displayName: 'AddEditForm',
})(Form);

export default AddEditForm;
