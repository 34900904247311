import Handlebars from 'handlebars';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import getTemplateData from './helpers';

import template from './templates/template';
import templateWithLogo from './templates/template-with-logo';
import previewTemplate from './templates/preview-template';
import previewTemplateWithLogo from './templates/preview-template-with-logo';

const generateHTML = async formInputs => {
  const data = getTemplateData(formInputs);
  let handlebarTemplate = template;
  if (data.logoUrl) {
    handlebarTemplate = templateWithLogo;
  }
  const compiledTemplate = Handlebars.compile(handlebarTemplate);
  const htmlString = compiledTemplate(data);
  const htmlBlob = new Blob([htmlString], { type: 'text/html' });
  try {
    const response = await fetch('/content.zip');
    if (!response.ok) throw new Error('Failed to fetch zip file');
    const zipBlob = await response.blob();
    const zip = new JSZip();
    zip.file('index.html', htmlBlob);
    zip.file('content.zip', zipBlob);
    const newZipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(newZipBlob, `${formInputs.name}.zip`);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const generateHTMLBlob = async formInputs => {
  const data = getTemplateData(formInputs);
  let handlebarTemplate = previewTemplate;
  if (data.logoUrl) {
    handlebarTemplate = previewTemplateWithLogo;
  }

  const compiledTemplate = Handlebars.compile(handlebarTemplate);
  const htmlString = compiledTemplate(data);
  const htmlBlob = new Blob([htmlString], { type: 'text/html' });
  return htmlBlob;
};

export default generateHTML;
