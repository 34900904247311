/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { ReactDateTimePicker } from '../../../components/elements';

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChartsColumnsItem = styled.div``;

const Seated = styled.div`
  width: 60px;
  height: 60px;
  background-color: #00aeef;
  color: white;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

const EmptySeat = styled.div`
  width: 60px;
  height: 60px;
  background-color: white;
  color: white;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

const TotalBookings = styled.div`
  width: 60px;
  height: 60px;
  background-color: #ee2a7b;
  color: white;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

const Label = styled.div`
  width: 60px;
  height: 60px;
  background-color: white;
  color: #505050;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

const FormSection = styled.section``;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const SelectTypeOfBox = ({ value }) => {
  if (value) {
    return <Seated>{value}</Seated>;
  }
  return <EmptySeat>&nbsp;</EmptySeat>;
};

const ChartsScreen = ({
  isActiveModal,
  onClose,
  tableBookingByTimeSlotsData,
  tableBookingByTimeSlotsDataLoading,
  chartDate,
  setChartDate,
}) => {
  if (tableBookingByTimeSlotsData) {
    const { slots, label } = tableBookingByTimeSlotsData;
    return (
      <div className={getContainerClassNames(isActiveModal)}>
        <div
          className="modal-background"
          style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
        />
        <div className="modal-card" style={{ width: '75%', height: '75%' }}>
          <header className="modal-card-head">
            <ModalTitle className="modal-card-title">Charts</ModalTitle>
            <button
              onClick={onClose}
              className="delete"
              aria-label="close"
              type="button"
            />
          </header>
          <FormSection className="modal-card-body">
            <ReactDateTimePicker
              value={chartDate}
              onChange={value => {
                setChartDate(new Date(value));
              }}
            />
            <ChartsContainer>
              <ChartsColumnsItem>
                <Label>&nbsp;</Label>
                {label.reverse().map(ele => (
                  <Label key={ele}>{ele}</Label>
                ))}
              </ChartsColumnsItem>
              {Object.keys(slots).map(ele => (
                <ChartsColumnsItem key={ele}>
                  <TotalBookings>{slots[ele].totalBookings}</TotalBookings>
                  {slots[ele].bookings.map(ele2 => (
                    <SelectTypeOfBox value={ele2} />
                  ))}
                  <Label>{ele}</Label>
                </ChartsColumnsItem>
              ))}
            </ChartsContainer>
          </FormSection>
        </div>
      </div>
    );
  }
  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Charts</ModalTitle>
          <button
            onClick={onClose}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>
        <FormSection className="modal-card-body">
          <ReactDateTimePicker
            value={chartDate}
            onChange={value => {
              setChartDate(new Date(value));
            }}
          />
          <br />
          {tableBookingByTimeSlotsDataLoading
            ? 'Loading...'
            : 'No Data Found...'}
        </FormSection>
      </div>
    </div>
  );
};

export default ChartsScreen;
