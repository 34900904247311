import React from 'react';

import styled from 'styled-components';

import { generateHTMLBlob } from './generateHTML';

const ButtonContainer = styled.div`
  display: flex;
  margin: 2rem;
`;

const IframeContainer = styled.div`
  height: 600px;
  margin: auto;
  overflow: hidden;
  color: white;
  border: 1px solid #e5e4e2;
  border-radius: 10px;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
`;
const Preview = ({ values }) => {
  const [viewport, setViewport] = React.useState('desktop');
  const [previewUrl, setPreviewUrl] = React.useState();

  const handleViewportChange = size => {
    setViewport(size);
  };

  const iframeRef = React.useRef(null);
  React.useEffect(() => {
    const f = async () => {
      const blob = await generateHTMLBlob(values);
      const url = URL.createObjectURL(blob);
      setPreviewUrl(url);
      if (iframeRef.current) {
        iframeRef.current.src = url;
      }
    };
    f();
  }, []);
  return (
    <div style={{ marginTop: '-2rem' }}>
      <ButtonContainer>
        <button
          className={`button is-large ${viewport === 'desktop' && 'is-active'}`}
          onClick={() => handleViewportChange('desktop')}
        >
          Desktop
        </button>
        <div style={{ margin: '0 1rem' }} />

        <button
          className={`button is-large ${viewport !== 'desktop' && 'is-active'}`}
          onClick={() => handleViewportChange('mobile')}
        >
          Mobile
        </button>

        <div style={{ margin: '0 1rem' }} />

        <button
          className={`button is-large `}
          onClick={() => window.open(previewUrl, '__blank')}
        >
          Preview in new window
        </button>
      </ButtonContainer>
      <IframeContainer
        style={{
          width: viewport === 'desktop' ? '1124px' : '375px',
        }}
      >
        <iframe
          title="preview-website"
          ref={iframeRef}
          style={{ width: '100%', height: '100%' }}
        />
      </IframeContainer>
    </div>
  );
};

export default Preview;
