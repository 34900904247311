import React, { useState } from 'react';
// import styled from 'styled-components';
// import { Table } from 'rbx';
import { useStoreState } from 'easy-peasy';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
// import swal from 'sweetalert';
// import {
//   isNull,
//   //  omit,
//   // isNaN,
// } from 'lodash';
import { toast } from 'react-toastify';

import client from '../../../utils/apolloClient';

// import gql from 'graphql-tag';
// import uuid from 'uuid';
// import { useMutation } from '@apollo/react-hooks';
// import { isNil } from 'lodash';
// import { toast } from 'react-toastify';

import {
  ErrorMessage,
  PageHeader,
  // Table as CustomTable,
  // Box,
} from '../../../components/elements';
// import Header from './Header';
// import List from './List';
import PlaceSelection from '../../../components/PlaceSelection';

// import AddEditForm from './AddEditForm';

// import client from '../../../utils/apolloClient';

// import FormModal from './FormModal';
// import { removeIdFromVariantsInput } from '../Item/helpers';
import Layout from '../../../components/global/Layout';

import { upload_excel_file } from '../../../utils/s3';
import { usePreLoadMenuItemsData } from './helpers';
import LogTable from './LogTable';

// const Container = styled.div`
//   .table-container {
//     min-height: 30rem;
//   }
// `;

const loadMenuItemMutation = gql`
  mutation loadMenuItemMutation(
    $placeId: String
    $userId: String
    $url: String
    $fileName: String
  ) {
    load_menu_item(
      input: {
        place_id: $placeId
        user_id: $userId
        url: $url
        file_name: $fileName
      }
    ) {
      error {
        code
        description
      }
      place_id
      status
      url
      user_id
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      pre_load_item_id
    }
  }
`;

const AddMenuItems = () => {
  const { userId } = useStoreState(state => state.auth);
  // const [menuItems, setMenuItems] = useState([]);
  // const [menuItem, setMenuItem] = useState();
  // const [isActiveModal, setIsActiveModal] = useState(false);
  // console.log({ userId, menuItem, isActiveModal });

  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [
    preLoadMenuItemsLoading,
    preLoadMenuItemsData,
    refetchPreLoadMenuItemsData,
  ] = usePreLoadMenuItemsData({ userId, placeId: place.place_id });

  if (placeSelection)
    return (
      <Layout>
        <Mutation
          client={client.clientPrivate}
          mutation={loadMenuItemMutation}
          onCompleted={({ load_menu_item }) => {
            if (Array.isArray(load_menu_item?.error)) {
              load_menu_item.error.map(item => toast.error(item.description));
            } else {
              // swal(
              //   'Great!',
              //   'Menu Items successfully uploaded!',
              //   'success',
              // ).then(() => {
              //   history.go(0);
              // });
            }
          }}
        >
          {(load_menu_item, { loading, error }) => (
            <>
              {error && <ErrorMessage message={error.message} />}
              <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                <PageHeader
                  title="Upload Items"
                  // eslint-disable-next-line max-len
                  otherText={`${place.name} | ${place.state} | ${place.city}, ${place.post_code} (${place.status} / ${place.claim_status})`}
                />
                <div>
                  <a
                    onClick={() => {
                      setPlaceSelection(false);
                      setPlace({});
                    }}
                    className="button is-grey m-3"
                  >
                    <span className="has-text-weight-semibold">Cancel</span>
                  </a>
                </div>
              </div>
              <div
                style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <div>
                  <p className="is-size-3">Upload File To S3</p>
                  <div
                    style={{
                      margin: '0.5rem',
                    }}
                  >
                    <input
                      type="file"
                      id="upload_csv_to_s3"
                      onChange={event => {
                        setExcelFile(event.target.files[0]);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    disabled={submitting || loading}
                    className={`button ${(submitting || loading) &&
                      'is-loading'} is-large is-fullwidth`}
                    style={{ fontSize: '12px' }}
                    onClick={async () => {
                      setSubmitting(true);
                      try {
                        if (excelFile) {
                          const reader = new FileReader();
                          reader.onload = async e => {
                            const fileContent = e.target.result;
                            const timestamp = new Date().getTime();
                            const res = await upload_excel_file(
                              fileContent,
                              `${place.place_id}_${timestamp}.csv`,
                            );
                            await load_menu_item({
                              variables: {
                                placeId: place.place_id,
                                userId,
                                url: res.Location,
                                fileName: `${place.place_id}_${timestamp}.csv`,
                              },
                            });
                            refetchPreLoadMenuItemsData();
                            setSubmitting(false);
                          };
                          reader.readAsText(excelFile);
                        } else {
                          setSubmitting(false);
                          toast.error('Please upload a file!');
                        }
                      } catch {
                        setSubmitting(false);
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <LogTable
                loading={preLoadMenuItemsLoading}
                preLoadedMenuItems={preLoadMenuItemsData}
              />

              {/* <Header
                setMenuItem={setMenuItem}
                setIsActiveModal={setIsActiveModal}
              />
              <button
                type="button"
                className={`button ${loading &&
                  'is-loading'} is-large is-fullwidth`}
                disabled={menuItems.length === 0}
                onClick={() => {
                  const createItemInput = [];
                  menuItems.forEach(inputs => {
                    if (inputs.variant && inputs.variant[0]) {
                      const allUnAvailable = inputs.variant[0].variants.every(
                        // eslint-disable-next-line arrow-body-style
                        variant => {
                          return variant.availability_status === 'UNAVAILABLE';
                        },
                      );

                      if (
                        allUnAvailable &&
                        inputs.availability_status === 'AVAILABLE'
                      ) {
                        swal(
                          'Oops!',
                          'All the variants cannot be sold out. Instead choose the item as Sold out!!',
                          'warning',
                        );
                        return;
                      }
                    }
                    const input = omit(
                      inputs,
                      'modifierGroups',
                      'updateLinks',
                      'createLinks',
                      'deleteLinks',
                      'dummy_price',
                      'choose_date',
                    );
                    let tempVariants;

                    if (Array.isArray(input.variant) && input.variant[0]) {
                      tempVariants = input.variant[0].variants.sort((a, b) => {
                        const variant_price_1 = Number(a.variant_price);
                        const variant_price_2 = Number(b.variant_price);
                        if (
                          !isNaN(variant_price_1) &&
                          !isNaN(variant_price_2)
                        ) {
                          if (variant_price_1 < variant_price_2) return -1;
                          if (variant_price_1 > variant_price_2) return 1;
                        }
                        return 0;
                      });
                    }

                    if (tempVariants) {
                      input.variant[0].variants = removeIdFromVariantsInput(
                        tempVariants,
                      );
                    }

                    createItemInput.push(input);
                  });

                  createItemInput.map(input =>
                    create_menu_item({
                      variables: {
                        input,
                      },
                    }),
                  );
                }}
              >
                Submit Items
              </button> */}
              <br />
              {/* <Box>
                <Container>
                  <CustomTable>
                    <Table.Head>
                      <Table.Row>
                        <Table.Heading>Item Name</Table.Heading>
                        <Table.Heading>Description</Table.Heading>
                        <Table.Heading>Actions</Table.Heading>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      <List
                        menuItems={menuItems}
                        onEdit={value => {
                          setMenuItem(value);
                          setIsActiveModal(true);
                        }}
                      />
                    </Table.Body>
                  </CustomTable>
                </Container>
              </Box> */}
            </>
          )}
        </Mutation>
      </Layout>
    );
  return (
    <Layout>
      <PlaceSelection
        heading="Select place to upload menu items"
        claimStatus="NONE"
        hasServiceSetting
        handleSelect={value => {
          setPlace(value);
          setPlaceSelection(true);
        }}
      />
    </Layout>
  );
};

export default AddMenuItems;
