import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
} from '../../../components/elements';

const usersQuery = gql`
  query users($input: SearchInput) {
    search_users(input: $input) {
      total_size
      total_pages
      user_listing {
        last_login
        user_id
        first_name
        last_name
        display_name
        email
        date_of_birth
        gender
        status
        role
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error {
          code
          description
        }
      }
    }
  }
`;

const AdminUsers = ({ location, history, placeIds }) => {
  const routeState = qs.parse(location.search);
  const { login, firstName, displayName, status, page, placeId } = routeState;

  const loginFilter = login || '';
  const firstNameFilter = firstName || '';
  const displayNameFilter = displayName || '';
  const statusFilter = status || 'ACTIVE';

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [userData, setUserData] = useState([]);

  const [sort, setSort] = useState('FIRSTNAME_DESC');
  const [firstNameSort, setFirstNameSort] = useState('AES');
  const [emailSort, setEmailSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const [listSize] = useState(20);
  const { userId } = useStoreState(state => state.auth);
  const input = {};
  const user_filter = { status: statusFilter, role: true };
  Object.assign(
    user_filter,
    placeIds && { place_id: placeIds },
    loginFilter !== '' && { email: loginFilter },
    firstNameFilter !== '' && { full_name: firstNameFilter },
    displayNameFilter !== '' && { display_name: displayNameFilter },
  );

  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    { user_id: userId },
    {
      filter: {
        user_filter,
        place_filter: {},
      },
    },
  );

  if (placeId) {
    input.filter.place_filter.place_id = placeId;
  }

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={statusFilter}
          login={loginFilter}
          firstName={firstNameFilter}
          displayName={displayNameFilter}
          history={history}
          routeState={routeState}
          placeId={placeId}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              history.push(
                `/admin-users${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEmailSort(emailSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          emailSort === 'AES' ? 'EMAIL_DESC' : 'EMAIL_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          emailSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Login
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setFirstNameSort(
                          firstNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          firstNameSort === 'AES'
                            ? 'FIRSTNAME_DESC'
                            : 'FIRSTNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          firstNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Full Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Roles</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Companies</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Phone Number</span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Last
                    Logged In
                  </span>
                </div>
              </th>

              <th />
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={usersQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_users } = data;
              return (
                <View
                  userData={userData}
                  setUserData={setUserData}
                  search_users={search_users}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default AdminUsers;

const View = ({
  search_users,
  setPageCount,
  setTotalElements,
  userData,
  setUserData,
}) => {
  useEffect(() => {
    setPageCount(search_users.total_pages);
    setTotalElements(search_users.total_size);
    if (search_users.user_listing) {
      setUserData(search_users.user_listing);
    }
  }, [search_users.total_pages]);
  return (
    <tbody>
      {userData.map(user => (
        <ListItem user={user} />
      ))}
    </tbody>
  );
};
