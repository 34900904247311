import React from 'react';
import { Column, Tab, Level } from 'rbx';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import Layout from '../../components/global/Layout';
import { Tabs, Heading, CheckBox } from '../../components/elements';
import {
  fetchAggregateMenuOrderDrilldown,
  fetchAggregateMenuOrderRollup,
  transformDrilldownData,
  transformRollupData,
} from './helpers';
import RollupTable from './RollupTable';
import DrilldownTable from './DrilldownTable';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Dashboard</Heading>
      </Level.Item>
    </Level>
  </Wrapper>
);

const Home = () => {
  const { userId } = useStoreState(state => state.auth);
  const [loading, setLoading] = React.useState(true);
  const [rollupTableData, setRollupTableData] = React.useState(null);
  const [drilldownTableData, setDrilldownTableData] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState('TODAY');
  const [excludeTestPlace, setExcludeTestPlace] = React.useState(false);
  const [isCashExclude, setIsCashExclude] = React.useState(false);

  React.useEffect(() => {
    const fetchPageData = async () => {
      setLoading(true);
      const [
        aggregateMenuOrderRollupData,
        aggregateMenuOrderDrilldownData,
      ] = await Promise.all([
        fetchAggregateMenuOrderRollup({
          userId,
          rangeType: activeTab,
          isTestPlace: !excludeTestPlace,
          isCashExclude,
        }),
        fetchAggregateMenuOrderDrilldown({
          userId,
          rangeType: activeTab,
          isTestPlace: !excludeTestPlace,
          isCashExclude,
        }),
      ]);
      console.log('aggregate data...');
      console.log('aggregateMenuOrderRollupData', aggregateMenuOrderRollupData);
      console.log(
        'aggregateMenuOrderDrilldownData',
        aggregateMenuOrderDrilldownData,
      );

      if (aggregateMenuOrderRollupData) {
        try {
          const transformData1 = transformRollupData(
            aggregateMenuOrderRollupData,
          );
          setRollupTableData(transformData1);
        } catch {
          setLoading(false);
        }
      } else {
        setRollupTableData(null);
      }

      if (aggregateMenuOrderDrilldownData) {
        try {
          const transformData2 = transformDrilldownData(
            aggregateMenuOrderDrilldownData,
          );
          setDrilldownTableData(transformData2);
        } catch {
          setLoading(false);
        }
      } else {
        setDrilldownTableData(null);
      }

      setLoading(false);
    };
    fetchPageData();
  }, [activeTab, excludeTestPlace, isCashExclude]);

  return (
    <Layout>
      <Header />
      <Column.Group
        style={{ padding: '1rem 1rem 0 1rem' }}
        vcentered
        multiline
        gapSize={8}
      >
        <Column size={3}>
          <CheckBox
            label="Exclude Test Restaurants"
            value={excludeTestPlace}
            onChange={value => {
              setExcludeTestPlace(value);
            }}
          />
        </Column>
        <Column size={3}>
          <CheckBox
            label="Exclude Cash Orders"
            name="event_business"
            value={isCashExclude}
            onChange={value => {
              setIsCashExclude(value);
            }}
          />
        </Column>
      </Column.Group>
      <RollupTable loading={loading} rollupTableData={rollupTableData} />
      <br />
      <Tabs className="tabs is-medium is-fullwidth">
        <Tab
          active={activeTab === 'TODAY'}
          onClick={() => setActiveTab('TODAY')}
        >
          TODAY
        </Tab>
        <Tab
          active={activeTab === 'YESTERDAY'}
          onClick={() => setActiveTab('YESTERDAY')}
        >
          YESTERDAY
        </Tab>
        <Tab active={activeTab === 'WEEK'} onClick={() => setActiveTab('WEEK')}>
          THIS WEEK
        </Tab>
        <Tab
          active={activeTab === 'MONTH'}
          onClick={() => setActiveTab('MONTH')}
        >
          THIS MONTH
        </Tab>
        <Tab active={activeTab === 'YEAR'} onClick={() => {}}>
          THIS YEAR
        </Tab>
      </Tabs>
      <DrilldownTable
        loading={loading}
        drilldownTableData={drilldownTableData}
      />
    </Layout>
  );
};

export default Home;
