import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  bottom: 0;
  background: #ffff;
`;

const Footer = ({ history, loading, showSubmit = false }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <a onClick={() => history.goBack()} className="button is-grey">
          <span className="has-text-weight-semibold">Cancel</span>
        </a>
      </Level.Item>
      {showSubmit && (
        <Level.Item
          align="right"
          style={{
            padding: 0,
            marginTop: 0,
            marginBottom: 0,
            position: 'relative',
            right: '0',
          }}
        >
          <button
            type="submit"
            className={`button is-primary ${loading &&
              'is-loading'} menu-designer-submit-button`}
            // disabled={!dirty}
          >
            <span className="has-text-weight-semibold">Submit</span>
          </button>
        </Level.Item>
      )}
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
