import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import ReactTooltip from 'react-tooltip';

import { differenceBy, sortBy, join, first } from 'lodash';
import client from '../../../utils/apolloClient';
import { CheckBox, Loading } from '../../../components/elements';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 160px) !important;
      position: absolute;
      right: auto !important;
      width: 55rem !important;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 1rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const SecondaryHeader = styled.div`
  background: white;
`;
const InputWrapper = styled.div`
  padding: 1rem;
  width: 50%;
`;

const EmptyMessage = styled.div`
  padding: 2rem;
`;

const searchMenuItemsQuery = gql`
  query searchMenuItems($input: SearchInput) {
    search_menu_modifiers(input: $input) {
      menu_modifier_listing {
        modifier_groups {
          name
        }
        modifier {
          menu_item_id
          name
          price
          availability_status

          variant {
            prompt_id
            prompt
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
            }
          }
        }
      }
    }
  }
`;

const calculatePrice = variants => {
  let price;
  const sort = sortBy(variants, 'variant_price');
  if (sort && sort.length !== 0) {
    price = `$${parseFloat(sort[0].variant_price).toFixed(2)}+`;
  }
  return price;
};

const SearchItemModal = ({
  onSubmit,
  isActive,
  onClose,
  initialsItems,
  placeId,
  isCreateLinkLoading,
  menuItemId,
}) => {
  const [items, setItems] = useState([]);
  console.log('items', first(items));
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // useEffect(() => {
  //   const finalMenuItems = differenceBy(
  //     selectedItems,
  //     initialsItems,
  //     'menu_item_id',
  //   );
  //   console.log("")
  //   setSelectedItems(finalMenuItems);
  // }, [initialsItems]);

  const fetchItems = value => {
    const menu_item_filter = {};
    Object.assign(
      menu_item_filter,
      { place_id: placeId },
      value && { name: value },
      { status: 'ACTIVE' },
    );
    setLoading(true);
    client.clientPublic
      .query({
        query: searchMenuItemsQuery,
        variables: {
          input: { sort: 'NAME_ASC', size: 200, filter: { menu_item_filter } },
        },
      })
      .then(({ data }) => {
        if (
          data.search_menu_modifiers &&
          data.search_menu_modifiers.menu_modifier_listing
        ) {
          const menuItems = data.search_menu_modifiers.menu_modifier_listing;

          const itemsF = menuItems.map(edge => ({
            ...edge.modifier,
            modifier_groups: edge.modifier_groups,
          }));

          const finalMenuItems = differenceBy(
            itemsF,
            initialsItems,
            'menu_item_id',
          );
          setItems(
            finalMenuItems.filter(edge => edge.menu_item_id !== menuItemId),
          );
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearch = value => {
    fetchItems(value);
  };

  useEffect(() => {
    fetchItems();
  }, [initialsItems]);

  const addItem = item => {
    console.log('bdjbvjjbvjwbv', item);
    setSelectedItems(selectedItems.concat(item));
  };

  const removeItem = id => {
    setSelectedItems(selectedItems.filter(item => item.menu_item_id !== id));
  };

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Items</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <SecondaryHeader>
          <InputWrapper>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="search"
                  placeholder="Search menu..."
                  onChange={e => onSearch(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search" />
                </span>
              </p>
            </div>
          </InputWrapper>
        </SecondaryHeader>

        <section className="modal-card-body">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th />
                <th>
                  <abbr>Item Name</abbr>
                </th>
                <th>
                  <abbr>Price</abbr>
                </th>
                <th>
                  <abbr>Used in Modifier groups</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                items.map(item => (
                  <tr>
                    <td>
                      <CheckBox
                        value={
                          selectedItems.filter(
                            edge => edge.menu_item_id === item.menu_item_id,
                          ).length !== 0
                        }
                        onChange={value =>
                          value
                            ? addItem({
                                ...item,
                                basePrice: item.price
                                  ? parseFloat(item.price).toFixed(2)
                                  : null,
                                override_price: null,
                                override_variant: item.variant.map(item2 => ({
                                  ...item2,
                                  variants: item2.variants.map(item3 => ({
                                    ...item3,
                                    basePrice: item3.variant_price,
                                    variant_price: null,
                                  })),
                                })),
                              })
                            : removeItem(item.menu_item_id)
                        }
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>
                      {item.variant && item.variant.length !== 0
                        ? calculatePrice(item.variant[0].variants)
                        : parseFloat(item.price).toFixed(2)}
                    </td>

                    <td>
                      <React.Fragment>
                        <p
                          data-tip={join(
                            item?.modifier_groups?.map(item2 => item2.name),
                            ', ',
                          )}
                          id={first(item.menu_item_id)}
                        >
                          {join(
                            item?.modifier_groups?.map(item2 => item2.name),
                            ', ',
                          )}
                        </p>
                        <ReactTooltip type="dark" />
                      </React.Fragment>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {loading && <Loading />}
          {!loading && items.length === 0 && (
            <EmptyMessage>
              <p>No Items found in this place</p>
            </EmptyMessage>
          )}
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-grey-light"
            aria-label="close"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${isCreateLinkLoading &&
              'is-loading'}`}
            aria-label="close"
            onClick={() => {
              onSubmit(selectedItems);
              setSelectedItems([]);
            }}
            disabled={selectedItems.length === 0}
          >
            <p className="has-text-weight-semibold">Add Item(s)</p>
          </button>
        </footer>
      </div>
    </Container>
  );
};

export default SearchItemModal;
