import React from 'react';
import { useStoreState } from 'easy-peasy';

import { getPlaces } from '../../UserManagement/AdminUser/helpers';
import { AsyncTagInputs } from '../../../components/elements';

const AssignPlaces = ({ onChange, placeId, tableBooking = false }) => {
  const [options, setOptions] = React.useState([]);
  const [places, setPlaces] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const { userId } = useStoreState(state => state.auth);

  const fetchPlaceFromPlaceId = async () => {
    const place_filter = {
      listing_status: 'APPROVED',
      event_business: false,
      item_review_place: false,
      has_service_type_setting: true,
      place_id: placeId,
    };

    if (tableBooking) {
      place_filter.service_type = 'Table booking';
    }

    const place = await getPlaces({
      sort: 'PLACE_NAME_ASC',
      user_id: userId,
      filter: {
        place_filter,
        service_type_filter: {
          name: 'Table booking',
        },
      },
    });

    if (place) {
      setValue({
        value: place,
        label: `${place[0].name}, ${place[0].city}, ${place[0].state}`,
      });
    }
  };

  const fetchPlaces = async (inputValue, resolve) => {
    const place_filter = {
      listing_status: 'APPROVED',
      event_business: false,
      item_review_place: false,
      has_service_type_setting: true,
    };

    Object.assign(place_filter, inputValue && { place_name: inputValue });

    const allPlaces = await getPlaces({
      sort: 'PLACE_NAME_ASC',
      user_id: userId,
      filter: {
        place_filter,
        service_type_filter: {
          name: 'Table booking',
        },
      },
    });

    if (allPlaces.length !== 0) {
      const filteredPlaces = allPlaces.map(item => ({
        value: item,
        label: `${item.name}, ${item.city}, ${item.state}`,
      }));

      setPlaces(filteredPlaces);
      if (resolve) {
        resolve(filteredPlaces);
      }
    }
  };

  React.useEffect(() => {
    if (placeId) fetchPlaceFromPlaceId();
  }, [placeId]);

  React.useEffect(() => {
    fetchPlaces();
  }, []);

  React.useEffect(() => {
    if (places && places.length !== 0) {
      setOptions([...places]);
    }
  }, [places]);

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      if (!inputValue) {
        fetchPlaces(null, resolve);
      } else {
        fetchPlaces(inputValue, resolve);
      }
    });

  return (
    <>
      <AsyncTagInputs
        label="Place"
        value={value}
        isMulti={false}
        suggestions={options}
        promiseFunction={promiseOptions}
        defaultOptions={options}
        onChange={e => {
          onChange(e.value.place_id);
        }}
      />
      <span
        style={{
          position: 'relative',
          top: '-10px',
          fontSize: '10px',
          textDecoration: 'underline',
          color: '#00AEEF',
          cursor: 'pointer',
        }}
        onClick={() => {
          setValue(null);
          if (placeId) {
            onChange('');
          }
        }}
      >
        clear selection
      </span>
    </>
  );
};

export default AssignPlaces;
