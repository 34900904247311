import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { omit } from 'lodash';
import { Button } from 'rbx';

import { ReactDateTimePicker } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;
const Search = ({
  startDate,
  endDate,
  routeState,
  history,
  onClick,
  downloadCsv,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/users-dashboard${qs.stringify(
        {
          ...newRoutState,
          ...args,
        },
        true,
      )}`,
    );
  };

  const setDateHours = dateObject => {
    dateObject.setHours(0, 0, 0, 0);
    return dateObject;
  };

  const startDateISOString = dateObject =>
    setDateHours(dateObject).toISOString();

  return (
    <Wrapper>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <ReactDateTimePicker
              value={startDate || startDateISOString(new Date())}
              // maxDate={moment(endDate || moment().toISOString()).toDate()}
              label="From"
              onChange={date => {
                const a = moment(startDateISOString(new Date(date)));
                const b = moment(endDate || moment().toISOString());
                const daysDiff = b.diff(a, 'days');
                if (daysDiff <= 0 || daysDiff > 45) {
                  handlePush(
                    {
                      startDate: startDateISOString(new Date(date)),
                      endDate: moment(date)
                        .set({
                          hour: 23,
                          minute: 59,
                          second: 0,
                          millisecond: 0,
                        })
                        .toISOString(),
                    },
                    'startDate',
                  );
                } else {
                  handlePush(
                    {
                      startDate: startDateISOString(new Date(date)),
                      // to: moment(date)
                      //   .add(45, 'days')
                      //   .toISOString(),
                    },
                    'startDate',
                  );
                }
              }}
              clear={false}
            />
          </div>
          <div className="column is-3">
            <ReactDateTimePicker
              minDate={moment(
                startDate || startDateISOString(new Date()),
              ).toDate()}
              maxDate={moment(startDate || startDateISOString(new Date()))
                .add(45, 'days')
                .toDate()}
              value={endDate || moment().toISOString()}
              label="To"
              onChange={date => {
                handlePush(
                  {
                    endDate: moment(date)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString(),
                  },
                  'endDate',
                );
              }}
              clear={false}
            />
          </div>
          <div className="column is-3" style={{ marginTop: '1.5rem' }}>
            <Button
              type="button"
              className={`button control ${downloadCsv && 'is-loading'}`}
              style={{
                marginLeft: '15px',
              }}
              onClick={onClick}
            >
              Download CSV
            </Button>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
