import React, { useState, useRef } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, isNaN } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import Form from './Form';

const createMenuItemMutation = gql`
  mutation createMenuItem($input: MenuItemInput) {
    create_menu_item(input: $input) {
      menu_item_id
      error {
        description
      }
    }
  }
`;

const createItemLinkMutation = gql`
  mutation createItemLink($input: [MenuItemLinkInput]) {
    create_menu_item_link(input: $input) {
      item_link_id
      place_id
      error {
        description
      }
    }
  }
`;

const ItemAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const { defaultPlace } = useStoreState(state => state.menu);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);
  const [createLinks, setCreateLinks] = useState([]);
  const [saveAndAddMore, setSaveAndAddMore] = useState(false);
  const resetFormFunc = useRef(() => {});

  const placeData = defaultPlace ?? place;
  const placeSelectionValue =
    defaultPlace !== null ? !!defaultPlace : placeSelection;

  return (
    <Layout>
      {placeSelectionValue ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createItemLinkMutation}
          variables={{ input: createLinks }}
          onCompleted={() => {
            swal('Great!', 'Menu Item created successfully!', 'success').then(
              () => {
                if (saveAndAddMore) {
                  if (resetFormFunc.current) {
                    resetFormFunc.current();
                  } else {
                    history.go(0);
                  }
                } else {
                  history.goBack();
                }
              },
            );
          }}
        >
          {(create_menu_item_link, { loading: createLinkLoading }) => (
            <Mutation
              client={client.clientPrivate}
              mutation={createMenuItemMutation}
              onCompleted={({ create_menu_item }) => {
                if (!isNull(create_menu_item.error)) {
                  create_menu_item.error.map(item =>
                    toast.error(item.description),
                  );
                } else if (createLinks.length !== 0) {
                  create_menu_item_link();
                } else {
                  swal(
                    'Great!',
                    'Menu Item created successfully!',
                    'success',
                  ).then(() => {
                    if (saveAndAddMore) {
                      if (resetFormFunc.current) {
                        resetFormFunc.current();
                      } else {
                        history.go(0);
                      }
                    } else {
                      history.goBack();
                    }
                  });
                }
              }}
            >
              {(create_menu_item, { loading, error }) => (
                <>
                  {error && <ErrorMessage message={error.message} />}

                  <Form
                    isCreatePage
                    setSaveAndAddMore={setSaveAndAddMore}
                    saveAndAddMore={saveAndAddMore}
                    userId={userId}
                    place={placeData}
                    loading={loading || createLinkLoading}
                    onSubmit={(inputs, resetForm) => {
                      resetFormFunc.current = resetForm;
                      if (inputs.variant && inputs.variant[0]) {
                        const allUnAvailable = inputs.variant[0].variants.every(
                          // eslint-disable-next-line arrow-body-style
                          variant => {
                            return (
                              variant.availability_status === 'UNAVAILABLE'
                            );
                          },
                        );

                        if (
                          allUnAvailable &&
                          inputs.availability_status === 'AVAILABLE'
                        ) {
                          swal(
                            'Oops!',
                            'All the variants cannot be sold out. Instead choose the item as Sold out!!',
                            'warning',
                          );
                          return;
                        }
                      }
                      setCreateLinks(inputs.createLinks);
                      const input = omit(
                        inputs,
                        'modifierGroups',
                        'updateLinks',
                        'createLinks',
                        'deleteLinks',
                        'dummy_price',
                        'choose_date',
                      );
                      let tempVariants;

                      if (Array.isArray(input.variant) && input.variant[0]) {
                        tempVariants = input.variant[0].variants.sort(
                          (a, b) => {
                            const variant_price_1 = Number(a.variant_price);
                            const variant_price_2 = Number(b.variant_price);
                            if (
                              !isNaN(variant_price_1) &&
                              !isNaN(variant_price_2)
                            ) {
                              if (variant_price_1 < variant_price_2) return -1;
                              if (variant_price_1 > variant_price_2) return 1;
                            }
                            return 0;
                          },
                        );
                      }

                      if (tempVariants) {
                        input.variant[0].variants = tempVariants;
                      }
                      create_menu_item({
                        variables: {
                          input,
                        },
                      });
                    }}
                  />
                </>
              )}
            </Mutation>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          heading="Select place to add a menu item"
          claimStatus="NONE"
          hasServiceSetting
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default ItemAdd;
