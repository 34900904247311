/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../../components/elements';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FormSection = styled.section``;

const TagName = styled.div`
  font-size: 10px;
  padding: 1rem;
  color: #505050;
  font-weight: 600;
`;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const TagFilterModal = ({
  initialTags,
  isActiveModal,
  onClose,
  changeRoute,
  tagsFromSetting,
}) => {
  const [tagArray, setTagArray] = React.useState(initialTags);

  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Tags</ModalTitle>
          <button
            className="button is-primary"
            onClick={() => {
              const t = btoa(tagArray);
              changeRoute(t);
              onClose();
            }}
            type="button"
          >
            Close & Apply Tag(s) Filter
          </button>
        </header>
        <FormSection className="modal-card-body">
          <>
            {tagsFromSetting.map(tg => {
              return (
                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                  key={tg}
                >
                  <TagName>
                    <span>{tg}</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={(() => {
                        const temp = new Set(tagArray);
                        if (temp.has(tg)) {
                          return true;
                        }
                        return false;
                      })()}
                      onChange={() => {
                        setTagArray(prev => {
                          const temp = new Set(prev);
                          let temp2 = [...prev];
                          if (temp.has(tg)) {
                            return temp2.filter(ele => ele !== tg);
                          }
                          temp2 = [...temp2, tg];
                          return temp2;
                        });
                      }}
                    />
                  </div>
                </Content>
              );
            })}
          </>
        </FormSection>
      </div>
    </div>
  );
};

export default TagFilterModal;
