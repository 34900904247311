import React from 'react';

import styled from 'styled-components';
import { Table, Button, Icon } from 'rbx';

import { useUserAccessData } from './helpers';

const Element = styled.div``;

const Delete = ({ userId, onDelete }) => {
  const userAccessData = useUserAccessData({ userId });

  const [loading, setLoading] = React.useState(false);

  if (loading) {
    return (
      <button
        type="button"
        className={`button is-primary ${loading && 'is-loading'}`}
      >
        &nbsp;
      </button>
    );
  }

  return (
    <Element>
      <Button.Group hasAddons>
        <Button
          onClick={async () => {
            setLoading(true);
            await onDelete(userAccessData);
            setLoading(false);
          }}
        >
          <Icon size="small">
            <i className="fas fa-trash has-text-grey" />
          </Icon>
        </Button>
      </Button.Group>
    </Element>
  );
};

const List = ({ admins, onDelete }) => (
  <>
    {admins.map(item => (
      <Table.Row>
        <Table.Cell>{item.user_id}</Table.Cell>
        <Table.Cell>{item.display_name}</Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell>{item.role.join(', ')}</Table.Cell>
        <Table.Cell>
          {item.contact &&
          Array.isArray(item.contact) &&
          item.contact.find(ele => ele.type === 'mobile') ? (
            <a
              href={`tel:${
                item.contact.find(ele => ele.type === 'mobile').value
              }`}
            >
              {item.contact.find(ele => ele.type === 'mobile').value}
            </a>
          ) : (
            '-'
          )}
        </Table.Cell>

        <Table.Cell>
          <Delete userId={item.user_id} onDelete={onDelete} />
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
