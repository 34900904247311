import React from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';

import { useStoreState } from 'easy-peasy';
import { Table } from '../../../components/elements';
import PlaceDetailsCard from './PlaceDetailsCard';
import OrderDetails from '../Order/OrderDetails';
import { getOrders, getPaymentTitle } from './helpers';

const Center1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Center2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const transformOrderData = order => {
  if (
    order &&
    Array.isArray(order.menu_order_listing) &&
    order.menu_order_listing[0]
  ) {
    return order.menu_order_listing[0];
  }
  return null;
};

const TransactionsOrdersTable = ({
  transactionsOrdersData,
  loading,
  //   onClick,
  placeData,
  //   placeId,
  canLoadMoreData,
  loadMoreData,
}) => {
  const [
    isActiveOrderDetailsModal,
    setIsActiveOrderDetailsModal,
  ] = React.useState(false);

  const [orderData, setOrderData] = React.useState(null);

  const [orderDataIsLoading, setOrderDataIsLoading] = React.useState(false);

  const [menuOrderId, setMenuOrderId] = React.useState({ id: null });

  const { userId } = useStoreState(state => state.auth);

  React.useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      setOrderDataIsLoading(true);
      const data = await getOrders({
        from: 0,
        size: 50,
        user_id: userId,
        filter: {
          menu_order_filter: {
            menu_order_id: menuOrderId.id,
          },
        },
      });
      const fo = transformOrderData(data);
      if (!ignore && fo) {
        setOrderData(fo);
        setIsActiveOrderDetailsModal(true);
      }
      setOrderDataIsLoading(false);
    };
    if (menuOrderId.id) {
      fetchData();
    }
    return () => {
      console.log(
        'unmounting <or> ignoring previous graphql call to search_menu_order...',
      );
      ignore = true;
    };
  }, [menuOrderId]);

  return (
    <>
      <PlaceDetailsCard placeData={placeData} />
      <div style={{ position: 'relative', padding: '10px' }}>
        {orderDataIsLoading && (
          <Center2>
            <Loading type="spin" color="#363636" width="150px" height="150px" />
          </Center2>
        )}
        <h1
          id="transactions-orders-heading-csv-pdf"
          style={{ display: 'none' }}
        >
          Transaction Orders
        </h1>
        <Table id="transactions-orders-table">
          <thead>
            <tr>
              {[
                'Order No',
                'Order Date',
                'Service',
                'Amount',
                'Promo',
                'Subtotal',
                'Payout',
                'Payment',
                'App Fees',
                'C Fees',
                'Srv Fees',
                'Del Fees',
                'K Fees',
                'F Fees',
                'B Fees',
                'Total Gst',
                'P Gst',
                'K Gst',
              ].map(headerCellValue => (
                <th key={headerCellValue} style={{ fontWeight: 700 }}>
                  <div>
                    <span>{headerCellValue}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          {(transactionsOrdersData ?? []).length !== 0 && (
            <tbody>
              {transactionsOrdersData.map(ttData => (
                <tr
                  style={{ fontWeight: 600, cursor: 'pointer' }}
                  key={ttData.orderId}
                  onClick={() => {
                    setMenuOrderId({ id: ttData.orderId });
                  }}
                >
                  <td style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}>
                    {ttData.orderNumber}
                  </td>
                  <td>{ttData.orderDate}</td>
                  <td>{ttData.service}</td>
                  <td>{ttData.amount}</td>
                  <td>{ttData.Promo}</td>
                  <td>{ttData.Subtotal}</td>
                  <td>{ttData.Payout}</td>
                  <td>{getPaymentTitle(ttData)}</td>
                  <td>{ttData['Application Fees']}</td>
                  <td>{ttData['Royalty Fees']}</td>
                  <td>{ttData['Service Fees']}</td>
                  <td>{ttData['Delivery Fees']}</td>
                  <td>{ttData['KRAVEiN Fees']}</td>
                  <td>{ttData['Franchisee Fees']}</td>
                  <td>{ttData['B Fees']}</td>
                  <td>{ttData.totalGst}</td>
                  <td>{ttData.pGst}</td>
                  <td>{ttData.kGst}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {loading && (
          <Center1>
            <Loading type="spin" color="#363636" width="60px" height="60px" />
          </Center1>
        )}
        {canLoadMoreData && (
          <PaginationContainer>
            <ShowMoreButton type="button" onClick={loadMoreData}>
              <span>Show More</span>
            </ShowMoreButton>
          </PaginationContainer>
        )}
      </div>
      {isActiveOrderDetailsModal && (
        <OrderDetails
          dueTimeToBeDisplayedInOrderDetailsModal={null}
          delayTimeToBeDisplayedInOrderDetailsModal={null}
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          orderComponent="OrderDashboard"
          order={orderData}
          refetchData={() => {}}
        />
      )}
    </>
  );
};

export default TransactionsOrdersTable;
