/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isNaN } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { BoxWithHeader, InputAddOn } from '../../../components/elements';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ grow }) => grow && `flex-grow:${grow}`};
`;

const ButtonWrapper = styled.div`
  margin-left: 1rem;
`;

const Container = styled.div`
  margin: 1rem 0rem;
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.lightGreyAlpha : theme.white};
`;

const ItemContainer = styled.div`
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.lightGreyBeta : theme.white};
`;

const Item = styled(Flex)``;

const VariantItem = styled(Flex)`
  padding: 0.5rem 1rem;
`;

const IconWrapper = styled.span`
  padding-right: 1rem;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Input = ({
  value,
  onBlur,
  basePrice,
  meal_deal = false,
  updateOverrideDueToMealDeal,
  ...props
}) => {
  console.log('invalue', value, basePrice);

  // let isActive = false;
  // if (value === '0.00') {
  //   isActive = true;
  // } else {
  //   isActive =
  //     parseFloat(value).toFixed(2) === parseFloat(basePrice).toFixed(2);
  // }

  const isActive =
    parseFloat(value).toFixed(2) === parseFloat(basePrice).toFixed(2) ||
    isNaN(parseFloat(value));

  const [isButtonActive, setIsButtonActive] = useState(isActive);

  const handleBlur = e => {
    onBlur(e);
    if (isActive) {
      setIsButtonActive(true);
    }
  };

  React.useEffect(() => {
    if (meal_deal) {
      updateOverrideDueToMealDeal();
      const isABadOverride =
        parseFloat('0').toFixed(2) === parseFloat(basePrice).toFixed(2) ||
        isNaN(parseFloat('0'));
      if (!isABadOverride) {
        setIsButtonActive(false);
      } else {
        setIsButtonActive(false);
      }
    }
  }, [meal_deal]);

  if (isButtonActive) {
    return (
      <button
        type="button"
        className="button is-primary"
        onClick={() => {
          setIsButtonActive(false);
        }}
      >
        Price override
      </button>
    );
  }

  return (
    <InputAddOn
      value={parseFloat(value).toFixed(2)}
      onBlur={handleBlur}
      autoFocus={false}
      {...props}
    />
  );
};

const Items = ({ items, setFieldValue, meal_deal }) => {
  const [itemsData, setItemsData] = useState(items);

  useEffect(() => {
    setItemsData(items);
  }, [items]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      itemsData,
      result.source.index,
      result.destination.index,
    );
    setFieldValue('modifier', newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {itemsData.map((item, index) => (
              <Draggable
                key={item.menu_item_id}
                draggableId={item.menu_item_id}
                index={index}
              >
                {(provided2, snapshot2) => (
                  <ItemContainer
                    ref={provided2.innerRef}
                    {...provided2.draggableProps}
                    {...provided2.dragHandleProps}
                    isDragging={snapshot2.isDragging}
                  >
                    <BoxWithHeader
                      header={
                        <Item>
                          <Flex grow={2}>
                            <Flex>
                              <IconWrapper>
                                <i className="fas fa-arrows-alt" />
                              </IconWrapper>
                              <p className="is-size-2 has-text-weight-semibold">
                                {item.name}
                                {item.basePrice && (
                                  <div className="is-size-5 has-text-weight-regular has-text-primary">
                                    Base Price - ${item.basePrice}
                                  </div>
                                )}
                              </p>
                            </Flex>
                          </Flex>

                          <Flex>
                            &nbsp; &nbsp;
                            {item.override_variant &&
                              item.override_variant.length === 0 && (
                                <div>
                                  <Input
                                    meal_deal={meal_deal}
                                    updateOverrideDueToMealDeal={() =>
                                      items[index]?.override_price
                                        ? undefined
                                        : setFieldValue(
                                            `modifier.${index}.override_price`,
                                            '0',
                                          )
                                    }
                                    isLeft
                                    addonsText="$"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={item.override_price}
                                    onChange={e => {
                                      if (e.target.value === '') {
                                        setFieldValue(
                                          `modifier.${index}.override_price`,
                                          null,
                                        );
                                      } else if (
                                        !isNaN(parseFloat(e.target.value))
                                      ) {
                                        setFieldValue(
                                          `modifier.${index}.override_price`,
                                          parseFloat(e.target.value),
                                        );
                                      }
                                    }}
                                    onBlur={e => {
                                      if (e.target.value === '') {
                                        setFieldValue(
                                          `modifier.${index}.override_price`,
                                          item.basePrice,
                                        );
                                      } else if (
                                        !isNaN(parseFloat(e.target.value))
                                      ) {
                                        setFieldValue(
                                          `modifier.${index}.override_price`,
                                          parseFloat(e.target.value).toFixed(2),
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            <ButtonWrapper>
                              <button
                                type="button"
                                className="button"
                                onClick={() => {
                                  setFieldValue(
                                    'modifier',
                                    itemsData.filter(
                                      edge =>
                                        edge.menu_item_id !== item.menu_item_id,
                                    ),
                                  );
                                }}
                              >
                                <i className="far fa-trash-alt" />
                              </button>
                            </ButtonWrapper>
                          </Flex>
                        </Item>
                      }
                    >
                      {item.override_variant.length !== 0 &&
                        item.override_variant.map(
                          (item1, index1) =>
                            item1.variants.length !== 0 &&
                            item1.variants.map((item2, index2) => (
                              <VariantItem>
                                {console.log(' item1.variants', item2)}
                                <Flex grow={2}>
                                  <Flex>
                                    <IconWrapper>
                                      <i className="fas fa-long-arrow-alt-right" />
                                    </IconWrapper>
                                    <p className="is-size-3 is-capitalized">
                                      {item2.variant_name}
                                      {item2.basePrice && (
                                        <div className="is-size-5 has-text-weight-semibold has-text-primary">
                                          Base Price - ${item2.basePrice}
                                        </div>
                                      )}
                                    </p>
                                  </Flex>
                                </Flex>
                                <Flex>
                                  <div>
                                    <Input
                                      basePrice={item2.basePrice}
                                      isLeft
                                      addonsText="$"
                                      type="number"
                                      min="0"
                                      step="0.01"
                                      value={item2.variant_price}
                                      onChange={e => {
                                        if (e.target.value === '') {
                                          setFieldValue(
                                            // eslint-disable-next-line max-len
                                            `modifier.${index}.override_variant.${index1}.variants.${index2}.variant_price`,
                                            null,
                                          );
                                        } else if (
                                          !isNaN(parseFloat(e.target.value))
                                        ) {
                                          setFieldValue(
                                            // eslint-disable-next-line max-len
                                            `modifier.${index}.override_variant.${index1}.variants.${index2}.variant_price`,
                                            parseFloat(e.target.value),
                                          );
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.target.value === '') {
                                          setFieldValue(
                                            `modifier.${index}.override_variant.${index1}.variants.${index2}.variant_price`,
                                            item2.basePrice,
                                          );
                                        } else if (
                                          !isNaN(parseFloat(e.target.value))
                                        ) {
                                          setFieldValue(
                                            `modifier.${index}.override_variant.${index1}.variants.${index2}.variant_price`,
                                            parseFloat(e.target.value).toFixed(
                                              2,
                                            ),
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  &nbsp; &nbsp;
                                  <ButtonWrapper>
                                    <button type="button" className="button" />
                                  </ButtonWrapper>
                                </Flex>
                              </VariantItem>
                            )),
                        )}
                    </BoxWithHeader>
                  </ItemContainer>
                )}
              </Draggable>
            ))}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Items;
