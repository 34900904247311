/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../../components/elements';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FilterHeading = styled.h1`
  font-size: 10px;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #e2e2e2;
  text-align: center;
`;

const FormSection = styled.section``;

const TagName = styled.div`
  font-size: 10px;
  padding: 1rem;
  color: #505050;
  font-weight: 600;
`;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const StatusFilterModal = ({
  initialGuestStatusFilter,
  initialBookingStatusFilter,
  isActiveModal,
  onClose,
  changeRoute,
  tab,
}) => {
  const [bookingStatusFilter, setBookingStatus] = React.useState(
    initialBookingStatusFilter,
  );
  const [guestStatusFilter, setGuestStatus] = React.useState(
    initialGuestStatusFilter,
  );

  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Status</ModalTitle>
          <button
            className="button is-primary"
            onClick={() => {
              changeRoute({ guestStatusFilter, bookingStatusFilter });
              onClose();
            }}
            type="button"
          >
            Close & Apply Filter(s)
          </button>
        </header>
        <FormSection className="modal-card-body">
          <div className="is-flex is-justify-content-space-evenly">
            <div>
              <FilterHeading>Booking Status</FilterHeading>
              <>
                {(tab === 0 || tab === 1 || tab === 3) && (
                  <Content
                    style={{
                      width: '200px',
                      height: '40px',
                      border: '2px solid #e2e2e2',
                    }}
                  >
                    <TagName>
                      <span>Received</span>
                    </TagName>
                    <div>
                      <CheckBox
                        value={bookingStatusFilter === 'RECEIVED'}
                        onChange={() => {
                          if (bookingStatusFilter === 'RECEIVED') {
                            setBookingStatus();
                          } else {
                            setBookingStatus('RECEIVED');
                          }
                        }}
                      />
                    </div>
                  </Content>
                )}
                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Confirmed</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={bookingStatusFilter === 'CONFIRMED'}
                      onChange={() => {
                        if (bookingStatusFilter === 'CONFIRMED') {
                          setBookingStatus();
                        } else {
                          setBookingStatus('CONFIRMED');
                        }
                      }}
                    />
                  </div>
                </Content>
                {(tab === 0 || tab === 1 || tab === 3) && (
                  <Content
                    style={{
                      width: '200px',
                      height: '40px',
                      border: '2px solid #e2e2e2',
                    }}
                  >
                    <TagName>
                      <span>Waitlisted</span>
                    </TagName>
                    <div>
                      <CheckBox
                        value={bookingStatusFilter === 'WAITLISTED'}
                        onChange={() => {
                          if (bookingStatusFilter === 'WAITLISTED') {
                            setBookingStatus();
                          } else {
                            setBookingStatus('WAITLISTED');
                          }
                        }}
                      />
                    </div>
                  </Content>
                )}
                {/* <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Modified</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={bookingStatusFilter === 'Modified'}
                      onChange={() => {
                        if (bookingStatusFilter === 'Modified') {
                          setBookingStatus();
                        } else {
                          setBookingStatus('Modified');
                        }
                      }}
                    />
                  </div>
                </Content> */}

                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Cancelled</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={bookingStatusFilter === 'CANCELLED'}
                      onChange={() => {
                        if (bookingStatusFilter === 'CANCELLED') {
                          setBookingStatus();
                        } else {
                          setBookingStatus('CANCELLED');
                        }
                      }}
                    />
                  </div>
                </Content>
              </>
            </div>
            <div>
              <FilterHeading>Guest Status</FilterHeading>
              <>
                {/* <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Cancelled</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={guestStatusFilter === 'Cancelled'}
                      onChange={() => {
                        if (guestStatusFilter === 'Cancelled') {
                          setGuestStatus();
                        } else {
                          setGuestStatus('Cancelled');
                        }
                      }}
                    />
                  </div>
                </Content> */}
                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Upcoming</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={guestStatusFilter === 'UPCOMING'}
                      onChange={() => {
                        if (guestStatusFilter === 'UPCOMING') {
                          setGuestStatus();
                        } else {
                          setGuestStatus('UPCOMING');
                        }
                      }}
                    />
                  </div>
                </Content>
                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Checked in</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={guestStatusFilter === 'CHECKEDIN'}
                      onChange={() => {
                        if (guestStatusFilter === 'CHECKEDIN') {
                          setGuestStatus();
                        } else {
                          setGuestStatus('CHECKEDIN');
                        }
                      }}
                    />
                  </div>
                </Content>
                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>Seated</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={guestStatusFilter === 'SEATED'}
                      onChange={() => {
                        if (guestStatusFilter === 'SEATED') {
                          setGuestStatus();
                        } else {
                          setGuestStatus('SEATED');
                        }
                      }}
                    />
                  </div>
                </Content>

                <Content
                  style={{
                    width: '200px',
                    height: '40px',
                    border: '2px solid #e2e2e2',
                  }}
                >
                  <TagName>
                    <span>No Show</span>
                  </TagName>
                  <div>
                    <CheckBox
                      value={guestStatusFilter === 'No Show'}
                      onChange={() => {
                        if (guestStatusFilter === 'No Show') {
                          setGuestStatus();
                        } else {
                          setGuestStatus('No Show');
                        }
                      }}
                    />
                  </div>
                </Content>
              </>
            </div>
          </div>
        </FormSection>
      </div>
    </div>
  );
};

export default StatusFilterModal;
