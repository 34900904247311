import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import { CheckBox, InputAddOn } from '../../../components/elements';

const DetailsFormWrapper = styled.div`
  padding: 1rem;
`;

const ZoneSetting = ({ values, setFieldValue }) => (
  <DetailsFormWrapper>
    <Column.Group multiline gapSize={1}>
      <>
        <Column size="1">
          <CheckBox value />
        </Column>
        <Column size="2">
          <p className="is-size-5 has-text-weight-semibold">ZONE 1</p>
        </Column>
        <Column size="9">
          <Column.Group multiline vcentered gapSize={1}>
            <>
              <Column size="4">
                <InputAddOn
                  addonsText="$"
                  isLeft
                  type="number"
                  min="0"
                  label="Minimum Order Amount"
                  value={values.zone_1_amount}
                  onChange={e => {
                    setFieldValue('zone_1_amount', e.target.value);
                  }}
                />
              </Column>

              <Column size="4">
                <InputAddOn
                  addonsText="$"
                  isLeft
                  type="number"
                  min="0"
                  label="Delivery Fee"
                  value={values.zone_1_fee}
                  onChange={e => {
                    setFieldValue('zone_1_fee', e.target.value);
                  }}
                />
              </Column>
            </>
          </Column.Group>
        </Column>
      </>
    </Column.Group>
    <Column.Group multiline gapSize={1}>
      <>
        <Column size="1">
          <CheckBox value />
        </Column>
        <Column size="2">
          <p className="is-size-5 has-text-weight-semibold">ZONE 2</p>
        </Column>
        <Column size="9">
          <Column.Group multiline vcentered gapSize={1}>
            <>
              <Column size="4">
                <InputAddOn
                  addonsText="$"
                  isLeft
                  type="number"
                  min="0"
                  label="Minimum Order Amount"
                  value={values.zone_2_amount}
                  onChange={e => {
                    setFieldValue('zone_2_amount', e.target.value);
                  }}
                />
              </Column>
              <Column size="4">
                <InputAddOn
                  addonsText="$"
                  isLeft
                  type="number"
                  min="0"
                  label="Delivery Fee"
                  value={values.zone_2_fee}
                  onChange={e => {
                    setFieldValue('zone_2_fee', e.target.value);
                  }}
                />
              </Column>
            </>
          </Column.Group>
        </Column>
      </>
    </Column.Group>
    <Column.Group multiline gapSize={1}>
      <>
        <Column size="1">
          <CheckBox value />
        </Column>
        <Column size="2">
          <p className="is-size-5 has-text-weight-semibold">ZONE 3</p>
        </Column>
        <Column size="9">
          <Column.Group multiline vcentered gapSize={1}>
            <>
              <Column size="4">
                <InputAddOn
                  addonsText="$"
                  isLeft
                  type="number"
                  min="0"
                  label="Minimum Order Amount"
                  value={values.zone_3_amount}
                  onChange={e => {
                    setFieldValue('zone_3_amount', e.target.value);
                  }}
                />
              </Column>
              <Column size="4">
                <InputAddOn
                  addonsText="$"
                  isLeft
                  type="number"
                  min="0"
                  label="Delivery Fee"
                  value={values.zone_3_fee}
                  onChange={e => {
                    setFieldValue('zone_3_fee', e.target.value);
                  }}
                />
              </Column>
            </>
          </Column.Group>
        </Column>
      </>
    </Column.Group>
  </DetailsFormWrapper>
);

export default ZoneSetting;
