/* eslint-disable func-names */
import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../components/elements';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 80vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const protectedTaxes = new Set(['DFT', 'GST', 'WAT']);

const Form = props => {
  const {
    isActive,
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    touched,
    errors,
  } = props;

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Tax</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <Column.Group gapSize={8} multiline vcentered>
            <Column size="half">
              <Input
                label="Tax Name"
                value={values.name}
                onChange={event => setFieldValue(`name`, event.target.value)}
                required
                disabled={protectedTaxes.has(values.name)}
                errors={errors.name}
                touched={touched.name}
                disableToastMessage
              />
            </Column>

            <Column size="half">
              <Input
                label="Tax Value"
                value={values.value}
                type="number"
                min={0}
                onChange={event => {
                  if (
                    event.target.value &&
                    parseFloat(event.target.value) >= 0 &&
                    parseFloat(event.target.value) <= 99999
                  ) {
                    setFieldValue(`value`, event.target.value);
                  } else if (event.target.value.length === 0) {
                    setFieldValue(`value`, null);
                  }
                }}
                onBlur={event =>
                  setFieldValue(
                    `value`,
                    parseFloat(event.target.value).toFixed(2),
                  )
                }
                required
                errors={errors.value}
                touched={touched.value}
                disableToastMessage
              />
            </Column>
          </Column.Group>
        </section>

        <footer className="modal-card-foot">
          <button type="button" className="button" onClick={onClose}>
            cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </footer>
      </div>
    </Container>
  );
};

const AddEditForm = withFormik({
  mapPropsToValues: ({ tax }) => ({
    name: tax ? tax.name : null,
    value: tax ? tax.value : null,
    status: 'ACTIVE',
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .nullable('Tax Name is required!')
      .min(3, 'Please enter at least 3 characters')
      .max(20, 'Please enter less than 20 characters')
      .required('Tax Name is required!'),
    value: yup
      .string()
      .nullable('Tax Value is required!')
      .required('Tax Value is required!')
      .testNonNegativeValue('Tax Value should not be negative value'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit({
      name: values.name,
      value: values.value,
    });
  },
  enableReinitialize: true,
  displayName: 'AddEditForm',
})(Form);

export default AddEditForm;
