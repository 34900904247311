/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import qs from 'querystringify';
import { Tab } from 'rbx';
import { useStoreState } from 'easy-peasy';

// import styled from 'styled-components';
// import moment from 'moment';
import uuidv4 from 'uuid/v4';
// import gql from 'graphql-tag';

// import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';
import { Box, Pagination, Tabs } from '../../../components/elements';
import Header from './Header';

import Search from './Search';
import BookingActivityTable from './BookingActivityTable';
import { useBookingsCount, useTableBookingsData } from './helpers';

const TableBookingActivity = ({ history, location }) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);

  const routeState = qs.parse(location.search);
  const {
    page,
    placeFilter,
    cityFilter,
    startDateFilter,
    endDateFilter,
    bookingNumberFilter,
    bookingDateFilter,
  } = routeState;

  let { searchByBookingNumber, activeTab, searchByBookingDate } = routeState;

  if (searchByBookingNumber) {
    searchByBookingNumber = searchByBookingNumber === 'true';
  } else {
    searchByBookingNumber = false;
  }

  if (searchByBookingDate) {
    searchByBookingDate = searchByBookingDate === 'true';
  } else {
    searchByBookingDate = false;
  }

  if (activeTab) {
    // pass
  } else {
    activeTab = 'new';
  }

  let bookingStatus = null;
  let guestStatus = null;

  if (searchByBookingNumber || searchByBookingDate) {
    bookingStatus = null;
    guestStatus = null;
  } else if (activeTab === 'new') {
    bookingStatus = 'RECEIVED';
    guestStatus = null;
  } else if (activeTab === 'confirmed') {
    bookingStatus = 'CONFIRMED';
    guestStatus = null;
  } else if (activeTab === 'waitlisted') {
    bookingStatus = 'WAITLISTED';
    guestStatus = null;
  } else if (activeTab === 'cancelled') {
    bookingStatus = 'CANCELLED';
    guestStatus = null;
  } else if (activeTab === 'upcoming') {
    bookingStatus = null;
    guestStatus = 'UPCOMING';
  }

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [triggerRerender, setTriggerRerender] = useState(uuidv4());

  const handleChange = args => {
    history.push(
      `/table-booking-activity${qs.stringify(
        {
          ...routeState,
          page: 1,
          ...args,
        },
        true,
      )}`,
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTriggerRerender(uuidv4());
    }, [30000]);
    return () => clearInterval(timer);
  }, []);

  const [
    newBookingCount,
    ,
    confirmedBookingCount,
    ,
    waitlistedBookingCount,
    ,
    cancelledBookingCount,
    ,
    upcomingBookingCount,
    ,
  ] = useBookingsCount({
    adminUserId,
    placeName: placeFilter,
    startDate: startDateFilter,
    endDate: endDateFilter,
    city: cityFilter,
    triggerRerender,
  });

  console.log(upcomingBookingCount);

  const [
    loadingTableBookingsData,
    tableBookingsData,
    totalSizeOfTableBookingsData,
    totalPagesOfTableBookingsData,
    ,
  ] = useTableBookingsData({
    adminUserId,
    from: currentPage * 50,
    size: 50,
    bookingStatus,
    guestStatus,
    placeName: placeFilter,
    startDate: startDateFilter,
    endDate: endDateFilter,
    bookingReference: bookingNumberFilter,
    city: cityFilter,
    bookingDate: bookingDateFilter,
    triggerRerender,
  });

  // const countStateUpdater = {
  //   new: setNewBookingCount,
  //   confirmed: setConfirmedBookingCount,
  //   waitlisted: setWaitlistedBookingCount,
  //   cancelled: setCancelledBookingCount,
  //   upcoming: setUpcomingBookingCount,
  // }[activeTab];

  // const countState = {
  //   new: newBookingCount,
  //   confirmed: confirmedBookingCount,
  //   waitlisted: waitlistedBookingCount,
  //   cancelled: cancelledBookingCount,
  //   upcoming: upcomingBookingCount,
  // }[activeTab];

  // React.useEffect(() => {
  //   console.log('updating current tab count...');
  //   if (totalSizeOfTableBookingsData === 0) {
  //     // pass
  //   } else if (totalSizeOfTableBookingsData !== countState) {
  //     countStateUpdater(totalSizeOfTableBookingsData);
  //   }
  // }, [totalSizeOfTableBookingsData, countState]);

  return (
    <Layout>
      <Header />
      <Search
        bookingDateFilter={bookingDateFilter}
        placeName={placeFilter}
        city={cityFilter}
        startDate={startDateFilter}
        endDate={endDateFilter}
        searchByBookingNumber={searchByBookingNumber}
        searchByBookingDate={searchByBookingDate}
        bookingNumber={bookingNumberFilter}
        handleChange={handleChange}
      />
      <Box>
        {!(searchByBookingNumber || searchByBookingDate) && (
          <Tabs className="tabs is-medium is-fullwidth">
            <Tab
              active={activeTab === 'new'}
              onClick={() => handleChange({ activeTab: 'new' })}
            >
              New ({`${newBookingCount}`})
            </Tab>
            <Tab
              active={activeTab === 'confirmed'}
              onClick={() => handleChange({ activeTab: 'confirmed' })}
            >
              CONFIRMED ({`${confirmedBookingCount}`})
            </Tab>
            {/* <Tab
              active={activeTab === 'modified'}
              onClick={() => handleChange({ activeTab: 'modified' })}
            >
              MODIFIED ({`${newBookingCount}`})
            </Tab> */}
            <Tab
              active={activeTab === 'waitlisted'}
              onClick={() => handleChange({ activeTab: 'waitlisted' })}
            >
              WAITLISTED ({`${waitlistedBookingCount}`})
            </Tab>
            <Tab
              active={activeTab === 'cancelled'}
              onClick={() => handleChange({ activeTab: 'cancelled' })}
            >
              CANCELLED ({`${cancelledBookingCount}`})
            </Tab>
            {/* <Tab
              active={activeTab === 'upcoming'}
              onClick={() => handleChange({ activeTab: 'upcoming' })}
            >
              UPCOMING ({`${upcomingBookingCount}`})
            </Tab> */}
          </Tabs>
        )}
      </Box>
      <div className="mb-4">&nbsp;</div>
      {totalSizeOfTableBookingsData > 0 && (
        <Pagination
          pageFrom={currentPage * 50}
          pageCount={totalPagesOfTableBookingsData}
          listSize={50}
          totalElements={totalSizeOfTableBookingsData}
          currentPage={currentPage}
          handlePageClick={value => {
            history.push(
              `/table-booking-activity${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            );
          }}
        />
      )}
      <BookingActivityTable
        loading={loadingTableBookingsData}
        bookingActivityData={tableBookingsData}
        activeTab={activeTab}
        history={history}
      />
    </Layout>
  );
};

export default TableBookingActivity;
