import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ isSubmitting, handleSubmit, history, isAdd }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a
            onClick={() => history.goBack()}
            className="button is-outlined is-grey"
          >
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>

      <Level.Item align="right">
        <Level.Item>
          <button
            type="submit"
            className={`button is-primary ${isSubmitting && 'is-loading'}`}
            onClick={handleSubmit}
          >
            <span>{isAdd ? 'Add Booking' : 'Modify Booking'}</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Footer;
