import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import qs from 'querystringify';
import { omit } from 'lodash';
import {
  // Input,
  Radio,
} from '../../../components/elements';

import AssignPlaces from './AssignPlaces';
import LocationSuggestions from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  publish,
  // serviceType,
  routeState,
  history,
  placeId,
  city,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/table-booking-policy-listing${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '2rem',
        }}
      >
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Publish</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="ON"
                value={publish === 'ON'}
                onChange={() => {
                  handlePush({ publish: 'ON' }, 'publish');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="OFF"
                value={publish === 'OFF'}
                onChange={() => {
                  handlePush({ publish: 'OFF' }, 'publish');
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
      </div>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <AssignPlaces
              onChange={id => {
                handlePush({ placeId: id }, 'placeId');
              }}
              placeId={placeId}
              tableBooking
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestions
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush(
                      {
                        cityFilter: value.name,
                      },
                      'cityFilter',
                    );
                  }}
                  onHandleClear={() =>
                    handlePush(
                      {
                        cityFilter: '',
                      },
                      'cityFilter',
                    )
                  }
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>

          {/* <div className="column is-3">
            <Input
              label="Service Type"
              id="MenuDesigner_ServiceType"
              type="text"
              value={serviceType}
              onChange={event => {
                handlePush({ serviceType: event.target.value }, 'serviceType');
              }}
              placeholder="Enter the Service Type..."
            />
          </div> */}
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
