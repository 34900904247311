import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';

import Details from './Details';
import client from '../../../../utils/apolloClient';
import { Loading, Message } from '../../../../components/elements';

const usersQuery = gql`
  query users($input: SearchInput) {
    search_users(input: $input) {
      total_size
      total_pages
      user_listing {
        last_login
        user_id
        first_name
        last_name
        display_name
        email
        date_of_birth
        gender
        status
        role
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error {
          code
          description
        }
      }
    }
  }
`;

const Taxes = props => {
  const { userId } = useStoreState(state => state.auth);

  // const { data, loading, error, refetch } = useQuery(searchTaxQuery, {
  //   variables: {
  //     userId,
  //   },
  //   client: client.clientPrivate,
  // });

  const { data, loading, error, refetch } = useQuery(usersQuery, {
    variables: {
      input: {
        filter: {
          place_filter: {
            place_id: props.values.place_id,
          },
          user_filter: {
            role: true,
            status: 'ACTIVE',
          },
        },
        user_id: userId,
      },
    },
    client: client.clientPrivate,
  });

  return (
    <>
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Details
          admins={
            data?.search_users?.user_listing &&
            Array.isArray(data.search_users.user_listing)
              ? data.search_users.user_listing
              : []
          }
          refetch={refetch}
          placeId={props.place.place_id}
        />
      )}
    </>
  );
};

export default Taxes;
