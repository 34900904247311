import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => {
  const { defaultPlace } = useStoreState(state => state.menu);

  const { setDefaultPlace, setDefaultPlaceId } = useStoreActions(
    state => state.menu,
  );

  return (
    <>
      <Wrapper>
        <Level>
          <Level.Item align="left">
            <Title className="is-size-2">Manage Menu Items</Title>
          </Level.Item>
          <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action={['create_menu_item']}
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <Level.Item align="right">
                    <Link
                      to="/upload-menu-items"
                      className="button is-text m-2"
                    >
                      <span className="has-text-weight-semibold">
                        Upload Menu Items
                      </span>
                    </Link>
                    <Link to="/add-menu-item" className="button is-primary">
                      <span className="has-text-weight-semibold">
                        Add Menu Item
                      </span>
                    </Link>
                  </Level.Item>
                )}
              />
            )}
          </ACLSConsumer>
        </Level>
      </Wrapper>
      {defaultPlace && (
        <div style={{ marginLeft: '12px', marginTop: '6px' }}>
          <button
            style={{ fontSize: '10px', fontWeight: 700 }}
            onClick={() => {
              setDefaultPlace(null);
              setDefaultPlaceId(null);
            }}
            className="button is-primary"
          >
            <span>{defaultPlace.name}</span>
            <span className="icon is-small">
              <i className="fas fa-times" />
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default Header;
