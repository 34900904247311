import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useAuditUserData } from '../TableBookingActivity/helpers';

const AuditUser = ({ userId }) => {
  const auditUserData = useAuditUserData({ userId });

  if (!auditUserData) {
    return <>-</>;
  }
  return <>{auditUserData.display_name}</>;
};

const CheckInList = ({ tableSetting }) => (
  <>
    <td>
      <Link to={`/table-booking-setting/${tableSetting.place_id}`}>
        {tableSetting.place_name}
      </Link>
    </td>
    <td>{tableSetting?.place?.city}</td>
    {/* <td>{tableSetting.service_type}</td> */}
    <td>&nbsp;</td>

    <td>
      <AuditUser userId={tableSetting?.audit?.updated_by} />
    </td>
    <td>{moment(tableSetting?.audit?.updated_at).format('lll')}</td>
    {/* <td>&nbsp;</td> */}
  </>
);

export default CheckInList;
