import React from 'react';
import { Button } from 'rbx';

import { Input } from '../../../components/elements';

import config from '../../../utils/config';

const LinkButton = props => (
  <Button {...props} className={`${props.className} link-button`} />
);

const BookingUrl = ({ place }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyLink = (e, text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 1000 * 5);
      },
      err => {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  const placeSlug = place?.slug;
  const url =
    config.apiPrefix === ''
      ? `https://kravein.com.au/place/${placeSlug}/book`
      : `https://dev11.portal.kravein.com.au/place/${placeSlug}/book`;

  return (
    <div style={{ position: 'relative', top: '8px' }}>
      <Input
        label="Booking URL"
        value={url}
        disabled
        rightSideAction={
          <LinkButton
            type="button"
            onClick={e => {
              e.stopPropagation();
              if (!isCopied) {
                copyLink(e, url);
              }
            }}
          >
            {isCopied ? 'copied' : 'Copy Link'}
          </LinkButton>
        }
      />
    </div>
  );
};

export default BookingUrl;
