import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';

import client from '../../../../../utils/apolloClient';
import Form from './Form';
import { ErrorMessage } from '../../../../../components/elements';

const updateCustomHourMutation = gql`
  mutation updateCustomHourMutation($input: TableSettingInput) {
    update_table_setting(input: $input) {
      error {
        code
        description
      }
    }
  }
`;

const getDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const tempEnd = new Date(endDate);

  tempEnd.setDate(tempEnd.getDate() + 1);

  const end = new Date(tempEnd.toISOString());

  const daysDifference = Math.floor((end - start) / (1000 * 60 * 60 * 24)); // Calculate the difference in days
  const dateArray = Array.from({ length: daysDifference + 1 }, (_, index) => {
    const currentDate = new Date(start);
    currentDate.setDate(start.getDate() + index);
    return currentDate.toISOString().slice(0, 10);
  });
  return dateArray;
};

const AddEditHour = ({
  isActive,
  onHandleClose,
  fetchHours,
  customHours,
  //
  customHour,
  serviceTypeSettingId,
  placeId,
}) => {
  const { userId } = useStoreState(state => state.auth);

  const customHoursFromMenu = customHours;

  return (
    <div>
      <Mutation
        client={client.clientPrivate}
        mutation={updateCustomHourMutation}
        onCompleted={({ update_table_setting }) => {
          if (!isNull(update_table_setting.error)) {
            update_table_setting.error.map(item =>
              toast.error(item.description),
            );
          } else {
            swal('Great!', 'Custom Hour updated successfully!', 'success').then(
              () => {
                onHandleClose();
                fetchHours();
              },
            );
          }
        }}
      >
        {(createCustomHour, { loading, error }) => (
          <>
            {error && <ErrorMessage message={error.message} />}
            <Form
              isActive={isActive}
              isUpdate={customHour}
              onClose={() => {
                onHandleClose();
              }}
              userId={userId}
              hour={customHour}
              loading={loading}
              onSubmit={input => {
                if (customHour) {
                  const tempInput = {
                    ...input,
                    user_id: userId,
                  };

                  const createInput = {
                    user_id: tempInput.user_id,
                    service_type: 'Table booking',
                    service_type_setting_id: serviceTypeSettingId,
                    place_id: placeId,
                  };

                  const custom_hour = [];
                  const tempOptions = tempInput.option.map(ele => ({
                    start: ele.start,
                    end: ele.end,
                    start_in_sec: ele.start_in_sec,
                    end_in_sec: ele.end_in_sec,
                  }));
                  if (tempOptions.length === 0) {
                    custom_hour.push({
                      date: tempInput.day,
                      day: moment(tempInput.day, 'YYYY-MM-DD')
                        .format('ddd')
                        .toUpperCase(),
                      is_active: false,
                      close_all_day: true,
                      option: [],
                    });
                  } else {
                    custom_hour.push({
                      date: tempInput.day,
                      day: moment(tempInput.day, 'YYYY-MM-DD')
                        .format('ddd')
                        .toUpperCase(),
                      is_active: true,
                      option: tempOptions,
                    });
                  }
                  createInput.custom_hour = [
                    ...(customHoursFromMenu ?? [])
                      .filter(ele => customHour.date !== ele.date)
                      .map(ele => {
                        const { custom_hour_id, option, ...rest } = ele;

                        console.log(custom_hour_id);

                        const option2 = option.map(ele2 =>
                          omit(ele2, '__typename'),
                        );

                        if (
                          Array.isArray(option2) &&
                          option2.length === 0 &&
                          (ele.is_active === false || ele.is_active === null)
                        ) {
                          return {
                            ...omit(rest, '__typename'),
                            close_all_day: true,
                            option: option2,
                          };
                        }

                        return {
                          ...omit(rest, '__typename'),
                          option: option2,
                        };
                      }),
                    ...custom_hour,
                  ];
                  createCustomHour({
                    variables: {
                      input: createInput,
                    },
                  });
                } else {
                  const tempInput = {
                    ...input,
                    user_id: userId,
                  };

                  const sameDayCustomHourExists = (
                    customHoursFromMenu ?? []
                  ).find(ele => ele.date === tempInput.day);

                  if (sameDayCustomHourExists) {
                    toast.error('Custom Hour for this date already exits!');
                    return;
                  }

                  const createInput = {
                    user_id: tempInput.user_id,
                    service_type: 'Table booking',
                    service_type_setting_id: serviceTypeSettingId,
                    place_id: placeId,
                  };

                  const custom_hour = [];
                  const tempOptions = tempInput.option.map(ele => ({
                    start: ele.start,
                    end: ele.end,
                    start_in_sec: ele.start_in_sec,
                    end_in_sec: ele.end_in_sec,
                  }));
                  if (tempOptions.length === 0) {
                    custom_hour.push({
                      date: tempInput.day,
                      day: moment(tempInput.day, 'YYYY-MM-DD')
                        .format('ddd')
                        .toUpperCase(),
                      is_active: false,
                      close_all_day: true,
                      option: [],
                    });
                  } else {
                    custom_hour.push({
                      date: tempInput.day,
                      day: moment(tempInput.day, 'YYYY-MM-DD')
                        .format('ddd')
                        .toUpperCase(),
                      is_active: true,
                      option: tempOptions,
                    });
                  }
                  createInput.custom_hour = [
                    ...(customHoursFromMenu ?? []).map(ele => {
                      const { custom_hour_id, option, ...rest } = ele;
                      console.log(custom_hour_id);

                      const option2 = option.map(ele2 =>
                        omit(ele2, '__typename'),
                      );

                      if (
                        Array.isArray(option2) &&
                        option2.length === 0 &&
                        (ele.is_active === false || ele.is_active === null)
                      ) {
                        return {
                          ...omit(rest, '__typename'),
                          close_all_day: true,
                          option: option2,
                        };
                      }

                      return {
                        ...omit(rest, '__typename'),
                        option: option2,
                      };
                    }),
                    ...custom_hour,
                  ];

                  if (input.range && input.endDay) {
                    const customHoursArray = getDates(
                      input.day,
                      input.endDay,
                    ).map(ele => ({
                      ...custom_hour[0],
                      date: ele,
                      day: moment(ele, 'YYYY-MM-DD')
                        .format('ddd')
                        .toUpperCase(),
                    }));
                    createInput.custom_hour = [
                      ...(customHoursFromMenu ?? []).map(ele => {
                        const { custom_hour_id, option, ...rest } = ele;
                        console.log(custom_hour_id);

                        const option2 = option.map(ele2 =>
                          omit(ele2, '__typename'),
                        );

                        if (
                          Array.isArray(option2) &&
                          option2.length === 0 &&
                          (ele.is_active === false || ele.is_active === null)
                        ) {
                          return {
                            ...omit(rest, '__typename'),
                            close_all_day: true,
                            option: option2,
                          };
                        }

                        return {
                          ...omit(rest, '__typename'),
                          option: option2,
                        };
                      }),
                      ...customHoursArray,
                    ];

                    const customHourDates = createInput.custom_hour.map(
                      ele => ele.date,
                    );
                    if (
                      new Set(customHourDates).size !== customHourDates.length
                    ) {
                      swal(
                        // eslint-disable-next-line max-len
                        'Some of the selected days already have custom hours assigned. Please delete these custom hours and resubmit this request.',
                      );
                      return;
                    }
                  } else {
                    createInput.custom_hour = [
                      ...(customHoursFromMenu ?? []).map(ele => {
                        const { custom_hour_id, option, ...rest } = ele;
                        console.log(custom_hour_id);

                        const option2 = option.map(ele2 =>
                          omit(ele2, '__typename'),
                        );

                        if (
                          Array.isArray(option2) &&
                          option2.length === 0 &&
                          (ele.is_active === false || ele.is_active === null)
                        ) {
                          return {
                            ...omit(rest, '__typename'),
                            close_all_day: true,
                            option: option2,
                          };
                        }

                        return {
                          ...omit(rest, '__typename'),
                          option: option2,
                        };
                      }),
                      ...custom_hour,
                    ];
                  }

                  createCustomHour({
                    variables: {
                      input: createInput,
                    },
                  });
                }
              }}
            />
          </>
        )}
      </Mutation>
    </div>
  );
};

export default AddEditHour;
