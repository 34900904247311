import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ placeName = '', history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Food Order Override Fee</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
    {placeName && (
      <SubHeading className="is-size-2">
        {`${placeName && placeName.name}`}
      </SubHeading>
    )}

    {placeName && (
      <SubHeading className="is-size-2">
        {`${placeName && placeName.city}| ${placeName &&
          placeName.state}, ${placeName && placeName.post_code} (${placeName &&
          placeName.status})`}
      </SubHeading>
    )}

    {placeName &&
      placeName?.sales_rep?.first_name &&
      placeName?.sales_rep?.email && (
        <SubHeading className="is-size-3">
          Sales Rep - {placeName.sales_rep.first_name} (
          {placeName.sales_rep.email})
        </SubHeading>
      )}
  </Wrapper>
);

export default withRouter(Header);
