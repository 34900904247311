import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';
import { Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const SubHeading = ({ children, ...props }) => <h2 {...props}>{children}</h2>;

const Header = ({ place, history, loading, showSubmit = false }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Table Booking Setting</Heading>
      </Level.Item>
      {showSubmit && (
        <Level.Item
          align="right"
          style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
        >
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-grey">
              <span className="has-text-weight-semibold">Cancel</span>
            </a>
          </Level.Item>
          <Level.Item>
            <button
              type="submit"
              className={`button is-primary ${loading &&
                'is-loading'} menu-designer-submit-button`}
              // disabled={!dirty}
            >
              <span className="has-text-weight-semibold">Submit</span>
            </button>
          </Level.Item>
        </Level.Item>
      )}
    </Level>

    {place && (
      <React.Fragment>
        <SubHeading className="is-size-2">{place && place.name}</SubHeading>

        <div>
          <SubHeading className="is-size-2">
            {`${place && place.city}| ${place && place.state}, ${place &&
              place.post_code} (${place && place.status})`}
          </SubHeading>
        </div>
        {/* <div>
          <a
            onClick={() =>
              history.push(`/table-booking-manager/${place.place_id}`)
            }
            className="button is-grey"
          >
            <span className="has-text-weight-semibold">
              Go To Booking Manager
            </span>
          </a>
        </div> */}
      </React.Fragment>
    )}
  </Wrapper>
);

export default Header;
