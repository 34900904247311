import React from 'react';
import gql from 'graphql-tag';
import uuidv4 from 'uuid/v4';

import client from '../../../utils/apolloClient';

const searchTableBookingQuery = gql`
  query searchTableBookingQuery($input: SearchInput) {
    search_table_booking(input: $input) {
      total_pages
      total_size
      table_booking_listing {
        restaurant_notes
        address_line_1
        address_line_2
        app_type
        booking_date
        booking_reference
        booking_status
        booking_time
        city
        cancelled {
          created_at
          created_by
        }
        confirmed_count
        country
        guest_count
        guest_notes
        guest_status
        image_url
        platform
        service_type
        service_type_setting_id
        state
        table_booking_id
        table_number
        tags
        user_id
        waitlisted {
          status
        }
        contact {
          contact_email
          contact_phone
          first_name
          last_name
        }
      }
    }
  }
`;

export const searchTableBookings = async ({
  adminUserId,
  placeId,
  userId = null,
  bookingDate = null,
  bookingStatus = null,
  tableBookingId = null,
  guestStatus = null,
  dateFilter = null,
  tags = null,
  from,
  size,
}) => {
  const input = {
    user_id: adminUserId,
    size,
    from,
    filter: {
      place_filter: {
        place_id: placeId,
      },
      table_booking_filter: {},
    },
  };

  if (tags) {
    input.filter.table_booking_filter.tags = tags;
  }

  if (dateFilter) {
    input.filter.table_booking_filter.booking_date = dateFilter;
  }

  if (userId) {
    input.filter.table_booking_filter.user_id = userId;
  }

  if (bookingDate) {
    input.filter.table_booking_filter.booking_date = bookingDate;
  }

  if (bookingStatus) {
    input.filter.table_booking_filter.booking_status = bookingStatus;
  }

  if (tableBookingId) {
    input.filter.table_booking_filter.table_booking_id = tableBookingId;
  }

  if (guestStatus) {
    input.filter.table_booking_filter.guest_status = guestStatus;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking &&
      Array.isArray(data.data.search_table_booking?.table_booking_listing) &&
      data.data.search_table_booking?.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableBookingsData = ({
  adminUserId,
  placeId,
  userId = null,
  bookingDate = null,
  bookingStatus = null,
  tableBookingId = null,
  guestStatus = null,
  dateFilter = null,
  from = 0,
  size = 10,
  tags = null,
}) => {
  console.log('tag', tags);
  console.log('guestStatus', guestStatus);
  console.log('bookingStatus', bookingStatus);

  const [loading, setLoading] = React.useState(false);
  const [tableBookings, setTableBookings] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);

  const tagsStr = tags.join('%>%');

  const [refresh, setRefresh] = React.useState(uuidv4());

  React.useEffect(() => {
    const interval = setInterval(() => setRefresh(uuidv4()), [30000]);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setTableBookings([]);
      setPageCount(0);
      setTotalElements(0);
      if (adminUserId && placeId) {
        const res = await searchTableBookings({
          adminUserId,
          userId,
          placeId,
          bookingDate,
          bookingStatus,
          tableBookingId,
          guestStatus,
          dateFilter,
          from,
          size,
          tags:
            tagsStr.split('%>%').filter(ele => !!ele).length !== 0
              ? tagsStr.split('%>%')
              : null,
        });

        if (res) {
          setTableBookings(res.table_booking_listing);
          setPageCount(res.total_pages);
          setTotalElements(res.total_size);
        } else {
          // pass
        }
      } else {
        // pass
      }

      setLoading(false);
    };
    fetchFunc();
  }, [
    tagsStr,
    userId,
    placeId,
    bookingDate,
    bookingStatus,
    tableBookingId,
    guestStatus,
    dateFilter,
    from,
    size,
    refresh,
  ]);

  return [loading, tableBookings, pageCount, totalElements];
};
const searchTableBookingDetailsQuery = gql`
  query searchTableBookingDetailsQuery($input: SearchInput) {
    search_table_booking(input: $input) {
      total_size
      table_booking_listing {
        user_id
        restaurant_notes
        tags
        service_type_setting_id
        place_id
        platform
        guest_count
        guest_notes
        table_booking_id
        booking_date
        booking_time
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        service_type
        booking_reference
        booking_status
        guest_status
        image_url
        table_number
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        cancelled {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
        confirmed {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
        contact {
          contact_email
          contact_phone
          first_name
          last_name
        }
        received {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_type
          user_data {
            email
            first_name
            last_name
          }
        }
        waitlisted {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
      }
    }
  }
`;

export const getTableBookingsDetails = async ({
  adminUserId,
  tableBookingId,
}) => {
  const input = {
    user_id: adminUserId,
    filter: {
      table_booking_filter: {
        table_booking_id: tableBookingId,
      },
    },
  };

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingDetailsQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking &&
      Array.isArray(data.data.search_table_booking?.table_booking_listing) &&
      data.data.search_table_booking?.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking.table_booking_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableBookingsDetails = ({
  adminUserId,
  tableBookingId,
  reloadTableBookingData,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [tableBookingDetails, setTableBookingDetails] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setTableBookingDetails(null);
      if (adminUserId && tableBookingId) {
        const res = await getTableBookingsDetails({
          adminUserId,
          tableBookingId,
        });
        if (res) {
          setTableBookingDetails(res);
        } else {
          // pass
        }
      } else {
        // pass
      }
      setLoading(false);
    };

    fetchFunc();
  }, [adminUserId, tableBookingId, reloadTableBookingData]);

  return [
    loading,
    tableBookingDetails,
    async () => {
      setLoading(true);
      setTableBookingDetails(null);
      if (adminUserId && tableBookingId) {
        const res = await getTableBookingsDetails({
          adminUserId,
          tableBookingId,
        });
        if (res) {
          setTableBookingDetails(res);
        } else {
          // pass
        }
      } else {
        // pass
      }
      setLoading(false);
    },
  ];
};

const cancellTableBookingMutation = gql`
  mutation cancellTableBookingMutation(
    $tableBookingId: String
    $userId: String
  ) {
    update_table_booking(
      input: {
        booking_status: "CANCELLED"
        table_booking_id: $tableBookingId
        user_id: $userId
      }
    ) {
      error {
        code
        description
      }
      cancelled {
        created_at
        created_by
        note {
          date
          type
          value
        }
        status
        user_type
        user_data {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

export const cancellTableBooking = async ({ adminUserId, tableBookingId }) => {
  try {
    const data = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: cancellTableBookingMutation,
      variables: {
        userId: adminUserId,
        tableBookingId,
      },
    });

    if (
      data &&
      data?.data?.update_table_booking &&
      data.data.update_table_booking.error
    ) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const useCancellTableBooking = ({ adminUserId, tableBookingId }) => {
  const [loading, setLoading] = React.useState(false);
  const mutateFnc = async () => {
    setLoading(true);
    const res = await cancellTableBooking({ adminUserId, tableBookingId });
    setLoading(false);
    return res;
  };
  return [loading, mutateFnc];
};

const searchTableBookingByTimeQuery = gql`
  query searchTableBookingByTimeQuery(
    $date: String
    $placeId: String
    $userId: String
  ) {
    search_table_booking_by_time(
      input: {
        filter: {
          table_booking_filter: { booking_date: $date, place_id: $placeId }
        }
        user_id: $userId
      }
    ) {
      table_booking_listing {
        time_slot
        total_guest_count
        booking_details {
          guest_count
          table_number
        }
      }
    }
  }
`;

export const searchTableBookingByTime = async ({ date, placeId, userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingByTimeQuery,
      variables: {
        date,
        placeId,
        userId,
      },
    });
    if (
      data &&
      data?.data?.search_table_booking_by_time?.table_booking_listing &&
      Array.isArray(
        data.data.search_table_booking_by_time.table_booking_listing,
      ) &&
      data.data.search_table_booking_by_time.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking_by_time.table_booking_listing;
    }
    return null;
  } catch {
    return null;
  }
};

const transformBookingsArray = (arr, max) => {
  if (arr.length === max) {
    return arr.reverse();
  }

  return Array.from(
    { length: max },
    (value, index) => arr[index] || null,
  ).reverse();
};

export const useTableBookingByTimeSlotsData = ({ date, placeId, userId }) => {
  const [loading, setLoading] = React.useState(false);
  const [
    tableBookingByTimeSlotsData,
    setTableBookingByTimeSlotsData,
  ] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setTableBookingByTimeSlotsData(null);
      const res = await searchTableBookingByTime({ date, placeId, userId });
      if (res) {
        const temp = {};
        let max = 0;
        res.forEach(ele => {
          max = Math.max(max, ele.booking_details.length);
          temp[ele.time_slot] = {
            totalBookings: ele.total_guest_count,
            bookings: ele.booking_details.map(ele2 =>
              ele2.table_number
                ? `${ele2.table_number} (${ele2.guest_count})`
                : ele2.guest_count,
            ),
          };
        });

        const temp2 = {};
        Object.keys(temp).forEach(ele => {
          temp2[ele] = {
            ...temp[ele],
            bookings: transformBookingsArray(temp[ele].bookings, max),
          };
        });

        setTableBookingByTimeSlotsData({
          slots: temp2,
          label: Array.from({ length: max }, (_, index) => index + 1),
        });
      }
      setLoading(false);
    };

    fetchFunc();
  }, [date, placeId, userId]);

  return [loading, tableBookingByTimeSlotsData];
};

export default {};
