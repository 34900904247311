/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import { Table } from '../../components/elements';

const RollupTableContainer = styled.div`
  padding: 10px;
  fontweight: 700;
`;

const RollupTable = ({ rollupTableData, loading }) => (
  <RollupTableContainer>
    <Table>
      <thead style={{ backgroundColor: '#d0d0d0' }}>
        <tr>
          {[
            'All Orders',
            'All Sales',
            'App Fees',
            'Payout',
            'Misd Order',
            'Draft Order',
            'Avg Order',
            'Total Gst',
            'P Gst',
            'K Gst',
            'Delivery',
            'Sales',
            'Total Gst',
            'P Gst',
            'K Gst',
            'Dinein',
            'Sales',
            'Total Gst',
            'P Gst',
            'K Gst',
            'Pickup',
            'Sales',
            'Total Gst',
            'P Gst',
            'K Gst',
            'Del Fees',
            'Srv Fees',
            'K Fees',
            'F Fees',
            'B Fees',
          ].map((headerCellValue, index) => (
            <th
              style={{ border: '1px solid black', fontWeight: 700 }}
              key={`${headerCellValue}-${index}`}
            >
              <div>
                <span>{headerCellValue}</span>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody style={{ fontWeight: 700 }}>
        <tr>
          {loading || rollupTableData === null || rollupTableData === undefined
            ? Array.from({ length: 15 }, () => (
                <td
                  style={{
                    border: '1px solid black',
                    backgroundColor: '#d0d0d0',
                  }}
                  key={uuid()}
                >
                  -
                </td>
              ))
            : [
                rollupTableData.allOrders,
                rollupTableData.allSales,
                rollupTableData.appfee,
                rollupTableData.payout,
                rollupTableData.missedOrders,
                '-',
                rollupTableData.averageSales,

                rollupTableData.totalGst,
                rollupTableData.pGst,
                rollupTableData.kGst,

                rollupTableData.deliveryTotalOrders,
                rollupTableData.deliverySales,
                rollupTableData.deliveryTotalGst,
                rollupTableData.deliveryPGst,
                rollupTableData.deliveryKGst,

                rollupTableData.dineinTotalOrders,
                rollupTableData.dineinSales,
                rollupTableData.dineinTotalGst,
                rollupTableData.dineinPGst,
                rollupTableData.dineinKGst,

                rollupTableData.pickupTotalOrders,
                rollupTableData.pickupSales,
                rollupTableData.pickupTotalGst,
                rollupTableData.pickupPGst,
                rollupTableData.pickupKGst,

                rollupTableData.delFees,
                rollupTableData.srvfee,
                rollupTableData.kFees,
                rollupTableData.fFees,
                rollupTableData.bFees,
              ].map((bodyCellValue, index) => (
                <td
                  style={{
                    border: '1px solid black',
                    backgroundColor: '#d0d0d0',
                  }}
                  key={`${bodyCellValue}-${index}`}
                >
                  {bodyCellValue}
                </td>
              ))}
        </tr>
      </tbody>
    </Table>
  </RollupTableContainer>
);

export default RollupTable;
