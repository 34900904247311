/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

import { Table as CustomTable, Box } from '../../../../components/elements';

import Header from './Header';
import List from './List';
import AddEditForm from './AddEditForm';
import client from '../../../../utils/apolloClient';
import { fetchUserAccess } from './helpers';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const updateUserMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      error {
        description
      }
    }
  }
`;

const Details = ({ admins, refetch, placeId }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);

  const [loadingUserAccessData, setLoadingUserAccessData] = useState(false);

  const [updateUser, { loading: loading2 }] = useMutation(updateUserMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_user }) => {
      if (!isNil(update_user.error)) {
        update_user.error.map(item => toast.error(item.description));
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  return (
    <React.Fragment>
      <Header setIsActiveModal={setIsActiveModal} />
      <Box>
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>User Id</Table.Heading>
                <Table.Heading>Name</Table.Heading>
                <Table.Heading>Email</Table.Heading>
                <Table.Heading>Roles</Table.Heading>
                <Table.Heading>Phone</Table.Heading>
                <Table.Heading>Delete</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                admins={admins}
                onDelete={async user => {
                  if (user) {
                    const input = { ...user };

                    delete input.__typename;
                    delete input.access.__typename;

                    const temp = user.access.place_id.filter(
                      ele => ele !== placeId,
                    );
                    input.access.place_id = temp;
                    await updateUser({ variables: { input } });
                  }
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          loading={loading2 || loadingUserAccessData}
          isActive={isActiveModal}
          onClose={() => setIsActiveModal(false)}
          onSubmit={async values => {
            setLoadingUserAccessData(true);
            const userObj = await fetchUserAccess({
              userId: values.admin_user?.user_id,
            });
            setLoadingUserAccessData(false);
            if (userObj && userObj?.access?.place_id) {
              const input = { ...userObj };

              delete input.__typename;
              delete input.access.__typename;

              if (Array.isArray(userObj.access.place_id)) {
                const places = [...userObj.access.place_id, placeId];
                input.access.place_id = places;
              } else {
                const place = [placeId];
                input.access.place_id = place;
              }
              updateUser({ variables: { input } });
            } else {
              toast.error('An unknown error occurred please try again later.');
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Details;
