import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Column } from 'rbx';

import {
  BoxWithHeader,
  SingleImageUpload,
  InputErrorMessage,
} from '../../../components/elements';

// import options from '../../../utils/optionsHelpers';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const PlaceImage = ({ values, setFieldValue, errors, touched }) => (
  <Container>
    <p className="has-text-weight-semibold is-size-2">Place Image</p>
    <Column.Group>
      <Column size="5">
        <BoxWithHeader title="Photos">
          <SingleImageUpload
            s3UploadPath="static-website-images/"
            imageValue={values.image_url}
            onChange={image => setFieldValue('image_url', image.url)}
            onDelete={() => {
              setFieldValue('image_url', null);
            }}
          />
          <InputErrorMessage
            errors={errors.image_url}
            touched={touched.image_url}
          />
        </BoxWithHeader>
      </Column>
    </Column.Group>
    <p className="has-text-weight-semibold is-size-2">Logo Icon</p>
    <Column.Group>
      <Column size="5">
        <BoxWithHeader title="Photos">
          <SingleImageUpload
            s3UploadPath="static-website-images/"
            imageValue={values.logo_url}
            onChange={image => setFieldValue('logo_url', image.url)}
            onDelete={() => {
              setFieldValue('logo_url', null);
            }}
          />
          <InputErrorMessage
            errors={errors.logo_url}
            touched={touched.logo_url}
          />
        </BoxWithHeader>
      </Column>
    </Column.Group>
  </Container>
);
export default withRouter(PlaceImage);
