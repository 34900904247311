import React, { useState, useEffect, useCallback } from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

import Header from './Header';
import { CheckBox, Loading, Select, Input } from '../../../components/elements';

import AvailabilityList from './AvailabilityList';
import { getLinks, getMenus } from './helpers';
import Layout from '../../../components/global/Layout';

const MenuFilterContainer = styled(Column)`
  && .label:not(:last-child) {
    margin-bottom: 0em;
  }
`;

const NoOrder = styled.div`
  text-align: center;
`;

const AvailabilityDetail = ({ location }) => {
  const { userId } = useStoreState(state => state.auth);
  const [links, setLinks] = useState([]);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [keyword, setKeyword] = useState('');
  const [isSoldOut, setIsSoldOut] = useState(false);
  const size = 100;
  const page = React.useRef(0);
  const [moreLoading, setMoreLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  const place = location?.state?.data;

  const refetchLinks = async () => {
    try {
      const select =
        selectedMenu.label === 'All Menus' ? [] : [selectedMenu.menu_id];

      const linksObj = await getLinks({
        sort: 'NAME_ASC',
        from: 0,
        size: 100,
        search_criteria: keyword,
        user_id: userId,
        filter: {
          item_availability_filter: {
            place_id: place.place_id,
            menu_id:
              selectedMenu && selectedMenu.label !== 'All Menus'
                ? select
                : menus.map(menu => menu.menu_id),
          },
        },
      });
      setLinks(linksObj.availability_item_link_listing);
    } catch (err) {
      // pass
    }
  };

  const fetchMoreLinks = async ({ soldOut = false, pageValue }) => {
    if (totalPages === pageValue) {
      return;
    }
    setMoreLoading(true);
    try {
      const select =
        selectedMenu.label === 'All Menus' ? [] : [selectedMenu.menu_id];

      const linksObj = await getLinks({
        sort: 'NAME_ASC',
        from: pageValue * size,
        size,
        user_id: userId,
        filter: {
          item_availability_filter: {
            place_id: place.place_id,
            menu_id:
              selectedMenu && selectedMenu.label !== 'All Menus'
                ? select
                : menus.map(menu => menu.menu_id),
            name: keyword,
          },
        },
      });
      if (soldOut) {
        setLinks(prev => [
          ...prev,
          ...linksObj.availability_item_link_listing.filter(
            ele => ele?.menu_item?.availability_status === 'UNAVAILABLE',
          ),
        ]);
      } else {
        setLinks(prev => [...prev, ...linksObj.availability_item_link_listing]);
      }
      page.current += 1;
      setMoreLoading(false);
    } catch (err) {
      setMoreLoading(false);
    }
  };

  const fetchLinks = useCallback(
    async ({ soldOut = false }) => {
      setLoading(true);
      try {
        const select =
          selectedMenu.label === 'All Menus' ? [] : [selectedMenu.menu_id];

        const linksObj = await getLinks({
          sort: 'NAME_ASC',
          from: 0,
          size: 100,
          user_id: userId,
          filter: {
            item_availability_filter: {
              place_id: place.place_id,
              menu_id:
                selectedMenu && selectedMenu.label !== 'All Menus'
                  ? select
                  : menus.map(menu => menu.menu_id),
              name: keyword,
            },
          },
        });
        setTotalPages(linksObj.total_pages);
        if (soldOut) {
          setLinks(
            linksObj.availability_item_link_listing.filter(
              ele => ele?.menu_item?.availability_status === 'UNAVAILABLE',
            ),
          );
        } else {
          setLinks(linksObj.availability_item_link_listing);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [selectedMenu, keyword, menus],
  );

  const fetchMenus = useCallback(async () => {
    setLoading(true);
    try {
      const menuList = await getMenus({
        placeId: place.place_id,
        userId,
      });

      const newMenuList = menuList.map(item => ({
        ...item,
        label: `${item.service_type} - ${item.name}`,
        value: item.menu_id,
      }));

      newMenuList.unshift({
        label: 'All Menus',
        value: 'All Menus',
      });
      setMenus(newMenuList);
      setLoading(false);
      setSelectedMenu({ label: 'All Menus', value: 'All Menus' });
    } catch (err) {
      setLoading(false);
    }
  }, [place.place_id, userId]);

  useEffect(() => {
    if (isSoldOut) {
      fetchLinks({ soldOut: true });
    } else {
      fetchLinks({});
    }
  }, [fetchLinks, isSoldOut]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  return (
    <Layout>
      <Header
        placeName={place.name}
        placeLocation={`${place.city}, ${place.post_code} | ${place.country} `}
      />

      <Column.Group style={{ paddingLeft: '1rem' }}>
        <Column size={4}>
          <Input
            type="text"
            value={keyword}
            placeholder="Search for items"
            onChange={e => setKeyword(e.target.value)}
          />
        </Column>
        <MenuFilterContainer size={4}>
          <Select
            placeholder="All Menu"
            value={selectedMenu ? { ...selectedMenu } : null}
            options={menus}
            onChange={value => {
              setSelectedMenu(value);
            }}
            isLoading={false}
          />
        </MenuFilterContainer>
        <Column size={4}>
          <CheckBox
            value={isSoldOut}
            label={
              isSoldOut
                ? `Show Sold out items only ${
                    loading ? '' : `(${links.length})`
                  }`
                : `Show Sold out items only`
            }
            onChange={value => {
              setIsSoldOut(value);
            }}
          />
        </Column>
      </Column.Group>
      {loading && <Loading />}
      {!loading && links.length === 0 && <NoOrder>No Data found</NoOrder>}
      {!loading && links.length !== 0 && (
        <AvailabilityList
          links={links}
          place={place}
          keyword={keyword}
          isSoldOut={isSoldOut}
          refetchLinks={refetchLinks}
          fetchMoreLinks={fetchMoreLinks}
          moreLoading={moreLoading}
          page={page}
        />
      )}
    </Layout>
  );
};

export default AvailabilityDetail;
