import React, { useState } from 'react';
import moment from 'moment';
import TimePicker from './TimePicker';
import DatePicker from './DatePicker';

const DateTimePicker = ({ label, value, onChange }) => {
  const [date, setDate] = useState(value || '09/08/2019 11:00 PM');
  const [datePicker, setDatePicker] = useState('09/08/2019');
  const [timePicker, setTimePicker] = useState('11:00 PM');

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <nav className="columns is-gapless">
          <div className="column">
            <DatePicker
              value={moment(date)}
              onChange={data => {
                setDatePicker(moment(data).format('L'));
                setDate(`${datePicker} ${timePicker}`);
                onChange(moment(`${datePicker} ${timePicker}`).toISOString());
              }}
            />
          </div>
          <div className="column">
            <TimePicker
              value={moment(date).format('LT')}
              onChange={data => {
                setTimePicker(data);
                setDate(`${datePicker} ${timePicker}`);
                onChange(`${datePicker} ${timePicker}`);
              }}
            />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default DateTimePicker;
