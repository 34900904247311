import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { withRouter } from 'react-router-dom';

import Layout from '../../../components/global/Layout';
import { Loading, Message, Blank } from '../../../components/elements';
import { ACLSConsumer } from '../../../utils/aclsContext';

import GenerateStaticSiteForm from './GenerateStaticSiteForm';

const placeQuery = gql`
  query fetch_place($placeId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      payment_account {
        account_id
      }
      place_id
      # vendor_id
      name
      slug
      trading_name
      tax_id {
        key
        value
      }
      tagline
      keywords
      description
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      item_review_place
      default_image_url
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }
      latitude
      longitude
      service_type {
        name
        description
        display_order
      }
      service_category {
        name
        description
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      feature {
        name
        description
        logo
        display_order
      }
      dining_style {
        name
        description
        display_order
      }
      dietary_information {
        name
        description
        display_order
      }
      parking_detail {
        name
        description
        display_order
      }
      public_transport {
        name
        description
        display_order
      }
      noise_level {
        name
        description
        display_order
      }
      dress_code {
        name
        description
        display_order
      }
      business_type {
        name
        description
        display_order
      }
      ambience {
        name
        description
        display_order
      }
      good_for {
        name
        description
        display_order
      }
      service_type {
        name
        description
        display_order
      }
      tag
      listing_status
      claim_status
      price_range
      slug
      featured
      event_business
      item_review_place
      business_hour {
        day
        is_active
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
      note {
        type
        value
        date
      }
      verification_mode
      status
      approval_status
      display_order
      url {
        uri
        display_order
      }
      voucher_count
      voucher_list {
        code
        description
        tagline
        display_order
      }

      tax {
        type
        value
      }
      default_url
      location
      #  stars
      review_count
      like_percentage
      claimant_id
      listing_audit {
        listing_approved_by
        listing_approved_at
        listing_contact_user_id
        listing_contact_name
        listing_contact_phone
        listing_contact_email
        listing_contact_role
        listing_verification_note {
          type
          value
          date
        }
      }
    }
  }
`;

const getNearestPlacesQuery = gql`
  query getNearestPlacesQuery($location: String) {
    search_places(
      input: {
        filter: { place_filter: { distance: "100km" } }
        location: $location
      }
    ) {
      place_listing {
        city
        state
        country
        post_code
      }
    }
  }
`;

const GenerateStaticWebsite = ({ match }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <div>
        <Query
          query={placeQuery}
          variables={{
            placeId: match.params.placeId,
          }}
          fetchPolicy="network-only"
        >
          {({ data: data1, loading, error, refetch }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>error</Message>;
            }

            const place = data1.fetch_place;

            // console.log('place', place);
            if (isNull(place)) {
              return <Blank message="This Place Not Found" />;
            }

            return (
              <Query
                query={getNearestPlacesQuery}
                variables={{
                  location: place.location,
                }}
                fetchPolicy="network-only"
              >
                {({
                  data: nearestLocations,
                  loading: loading2,
                  error: error2,
                }) => {
                  if (loading2) {
                    return <Loading />;
                  }
                  if (error2) {
                    return <Message>error</Message>;
                  }

                  return (
                    <ACLSConsumer>
                      {() => (
                        <GenerateStaticSiteForm
                          isClaimed
                          nearestLocations={
                            Array.isArray(
                              nearestLocations?.search_places?.place_listing,
                            )
                              ? nearestLocations.search_places.place_listing
                              : []
                          }
                          refetch={refetch}
                          userId={userId}
                          place={place}
                        />
                      )}
                    </ACLSConsumer>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default withRouter(GenerateStaticWebsite);
