import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { toast } from 'react-toastify';
import UploadImage from '../../../components/elements/UploadImage';
import UpdateUploadImage from '../../../components/elements/UpdateUploadImage';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const deleteMutation = gql`
  mutation delete($userId: String, $imageId: String) {
    delete_image(input: { user_id: $userId, image_id: $imageId }) {
      image_id
      error {
        description
      }
    }
  }
`;

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      name
      default_image_url
      error {
        description
      }
    }
  }
`;

const GalleryImages = ({ setFieldValue, values, setValues }) => {
  const { userId } = useStoreState(state => state.auth);
  const [whichImageSettingDefault, setWhichImageSettingDefault] = useState(
    null,
  );

  const [updatePlace, { loading: updatePlaceLoading }] = useMutation(
    updatePlaceMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ update_place }) => {
        setFieldValue('default_image_url', update_place.default_image_url);
      },
    },
  );

  const handleRemoveUploadedImage = id => {
    const currentImage = values.uploadedImages.filter(item => item.id === id)[0]
      .url;
    if (currentImage === values.current_default_image_url) {
      toast.error(
        'You cannot delete a default image. Please select another image as the default image and submit it after that you are able to delete this image.',
      );
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this image!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          setValues({
            ...values,
            uploadedImages: values.uploadedImages.filter(
              item => item.id !== id,
            ),
          });
          client.clientPrivate
            .mutate({
              mutation: deleteMutation,
              variables: { userId, imageId: id },
            })
            .then(result => {
              console.log('result', result);
            })
            .catch(error => {
              console.log('result', error);
            });
        }
      });
    }
  };

  const handleRemoveCurrentImage = id => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this image!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        setValues({
          ...values,
          image: values.image.filter(item => item.id !== id),
        });
      }
    });
  };

  return (
    <Container>
      <p className="has-text-weight-semibold is-size-2">Place Images</p>
      <div className="columns is-multiline">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={['create_place', 'update_place']}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <UpdateUploadImage
                  removeDefault
                  action={['create_place', 'update_place']}
                  s3UploadPath="static-website-images/"
                  metaData={{
                    place_id: values.place_id,
                    user_id: userId,
                  }}
                  setFieldValue={setFieldValue}
                  handleRemoveUploadedImage={handleRemoveUploadedImage}
                  defaultImageLoading={updatePlaceLoading}
                  whichImageSettingDefault={whichImageSettingDefault}
                  setDefaultImage={image => {
                    setWhichImageSettingDefault(image.url);
                    updatePlace({
                      variables: {
                        input: {
                          place_id: values.place_id,
                          user_id: userId,
                          default_image_url: image.url,
                        },
                      },
                    });
                  }}
                  defaultImage={values.default_image_url}
                />
              )}
              no={() =>
                values.uploadedImages.map(item => (
                  <div className="column is-3">
                    <figure
                      className="image is-16by9"
                      opacity={item.uploadingStatus === 'uploading' ? 0.5 : 1}
                    >
                      <img src={item.preview} alt="image-upload" />
                    </figure>
                  </div>
                ))
              }
            />
          )}
        </ACLSConsumer>

        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={['create_place', 'update_place']}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <UploadImage
                  removeDefault
                  s3UploadPath="static-website-images/"
                  metaData={{
                    place_id: values.place_id,
                    user_id: userId,
                  }}
                  setFieldValue={setFieldValue}
                  handleRemoveCurrentImage={handleRemoveCurrentImage}
                  setDefaultImage={image =>
                    setFieldValue('default_image_url', image.url)
                  }
                  defaultImage={values.default_image_url}
                />
              )}
            />
          )}
        </ACLSConsumer>
      </div>
    </Container>
  );
};
export default withRouter(GalleryImages);
