import React from 'react';
import moment from 'moment-timezone';

import { Table } from '../../../components/elements';
import { useAuditUserData } from './helpers';

const durationColumnValue = createdAt => {
  let duration = '-';

  if (createdAt) {
    duration = moment(createdAt).fromNow();
  }

  // eslint-disable-next-line consistent-return
  return duration;
};

const AuditUser = ({ userId }) => {
  const auditUserData = useAuditUserData({ userId });

  if (!auditUserData) {
    return <span>-</span>;
  }
  return <span>{auditUserData.email}</span>;
};

const LogTable = ({ loading, preLoadedMenuItems }) => (
  <>
    <Table>
      <thead>
        <tr>
          <th>
            <div>
              <span>
                <a>
                  <i />
                </a>
                Place Name
              </span>
            </div>
          </th>
          <th>
            <span>Email</span>
          </th>
          <th>
            <span>URL</span>
          </th>
          <th>
            <span>Error Logs</span>
          </th>
          <th>
            <span>status</span>
          </th>
          <th>
            <span>Created By</span>
          </th>
          <th>
            <span>Created At</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {preLoadedMenuItems.map(plm => (
          <tr key={plm.pre_load_item_id}>
            <td
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {plm.place_name}
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <span> {plm.email}</span>
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <span> {plm.url}</span>
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {plm.error_file_url ? (
                <span>
                  <a
                    href={plm.error_file_url}
                    style={{ color: '#00AEEF' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click Here To View Logs
                  </a>
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
            <td
              style={{
                whiteSpace: 'nowrap',
                color: '#00AEEF',
                textDecoration: 'underlined',
              }}
            >
              <span> {plm.status}</span>
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <AuditUser userId={plm?.audit?.created_by} />
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {durationColumnValue(plm?.audit?.created_at)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    {loading && (
      <span
        style={{
          padding: '1rem',
          color: '#00AEEF',
          fontSize: '14px',
        }}
      >
        Loading...
      </span>
    )}
    {!loading && preLoadedMenuItems.length === 0 && (
      <span
        style={{
          padding: '1rem',
          color: '#505050',
          fontSize: '14px',
        }}
      >
        No Data Available!
      </span>
    )}
  </>
);

export default LogTable;
