import React from 'react';
import styled from 'styled-components';

// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;

const CardContainer = styled.main`
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  .card {
    position: relative;
    width: 7rem;
    height: 7rem;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: rgb(240, 240, 240);
    box-shadow: 5px rgba(0, 0, 0, 0.2);
  }

  .card:hover {
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
  }

  .card-content {
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
  }

  h2 {
    text-align: center;
    font-size: 10px;
    font-weight: semibold;
  }

  .pricing {
    width: 60%;
    position: absolute;
    bottom: 0;
    text-align: center;
  }

  p {
    font-weight: bold;
    margin: 10px 0;
    font-size: 12px;
  }
`;

const Card = ({ heading, content }) => (
  <CardContainer>
    <div className="card">
      <div className="card-content">
        <h2>{heading}</h2>
        <div className="pricing">
          <p>{content}</p>
        </div>
      </div>
    </div>
  </CardContainer>
);

export default Card;
