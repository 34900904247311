import React from 'react';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';

const Header = ({ setFee, setIsActiveModal }) => (
  <PageHeader title="Taxes">
    <Level.Item align="right">
      <Level.Item>
        <button
          type="submit"
          className="button is-primary"
          onClick={() => {
            setFee(null);
            setIsActiveModal(true);
          }}
        >
          <span>Add</span>
        </button>
      </Level.Item>
    </Level.Item>
  </PageHeader>
);

export default Header;
