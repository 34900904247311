import React from 'react';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const fetchAuditUserDataQuery = gql`
  query searchUsersQuery($userId: [String]) {
    search_users(input: { filter: { user_filter: { user_id: $userId } } }) {
      user_listing {
        display_name
        email
      }
    }
  }
`;

const fetchAuditUserData = async ({ userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: fetchAuditUserDataQuery,
      variables: {
        userId: [userId],
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_users &&
      Array.isArray(data.data.search_users?.user_listing) &&
      data.data.search_users?.user_listing.length !== 0
    ) {
      return data.data.search_users.user_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useAuditUserData = ({ userId }) => {
  const [auditUserData, setAuditUserData] = React.useState(null);
  React.useEffect(() => {
    let loadData = true;
    const fetchFunc = async () => {
      setAuditUserData(null);
      const res = await fetchAuditUserData({ userId });
      if (loadData) {
        if (res) {
          setAuditUserData(res);
        }
      }
    };
    fetchFunc();
    return () => {
      loadData = false;
    };
  }, [userId]);
  return auditUserData;
};

const fetchPreLoadMenuItemsQuery = gql`
  query fetchPreLoadMenuItemsQuery($placeId: String, $userId: String) {
    fetch_pre_load_menu_items(
      input: {
        filter: { pre_load_menu_item_filter: { place_id: $placeId } }
        user_id: $userId
      }
    ) {
      pre_loaded_menu_items {
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error_file_url
        place_id
        pre_load_item_id
        status
        url
        user_id
        email
        place_name
      }
      total_pages
      total_size
    }
  }
`;

const fetchPreLoadMenuItems = async ({ placeId, userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: fetchPreLoadMenuItemsQuery,
      variables: {
        userId,
        placeId,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data?.data?.fetch_pre_load_menu_items?.pre_loaded_menu_items &&
      Array.isArray(data.data.fetch_pre_load_menu_items.pre_loaded_menu_items)
    ) {
      return data.data.fetch_pre_load_menu_items.pre_loaded_menu_items;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const usePreLoadMenuItemsData = ({ placeId, userId }) => {
  const [preLoadMenuItemsLoading, setPreLoadMenuItemsLoading] = React.useState(
    false,
  );
  const [preLoadMenuItemsData, setPreLoadMenuItemsData] = React.useState([]);

  React.useEffect(() => {
    let loadData = true;
    const fetchFunc = async () => {
      setPreLoadMenuItemsData([]);
      setPreLoadMenuItemsLoading(true);
      if (placeId && userId) {
        const res = await fetchPreLoadMenuItems({ placeId, userId });
        if (loadData) {
          if (res) {
            setPreLoadMenuItemsData(res);
          }
        }
      }
      setPreLoadMenuItemsLoading(false);
    };
    fetchFunc();
    return () => {
      loadData = false;
    };
  }, [userId, placeId]);
  return [
    preLoadMenuItemsLoading,
    preLoadMenuItemsData,
    async () => {
      setPreLoadMenuItemsData([]);
      setPreLoadMenuItemsLoading(true);
      if (placeId && userId) {
        const res = await fetchPreLoadMenuItems({ placeId, userId });
        if (res) {
          setPreLoadMenuItemsData(res);
        }
      }
      setPreLoadMenuItemsLoading(false);
    },
  ];
};

export default {};
