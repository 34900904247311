import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { Label, Column } from 'rbx';

import Input from '../../../components/elements/Input';
import {
  TextArea,
  MobileInput,
  InputErrorMessage,
} from '../../../components/elements';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
  }
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const Contacts = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,

  onlyAdditionalContact,
  action,
}) => (
  // const isPrimaryEmailField = () => {
  //   const data = values.contact.filter(
  //     item => item.type === 'email' && item.is_primary === true,
  //   );
  //   const isPrimary = data.length === 0;
  //   return isPrimary;
  // };

  <Container>
    <FieldArray
      name="contact"
      render={() => (
        <React.Fragment>
          {!onlyAdditionalContact && (
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={6}>
                <Input
                  action={action}
                  label="Primary Contact Email"
                  id="PlaceManagement_AddEditPlace_PrimaryContactEmail"
                  name="primaryEmail"
                  value={values.primaryEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputErrorMessage
                  errors={errors.primaryEmail}
                  touched={touched.primaryEmail}
                />
              </Column>
              <Column size={5}>
                <MobileInput
                  action={action}
                  label=" Primary Contact Phone"
                  id="PlaceManagement_AddEditPlace_PrimaryContactPhone"
                  value={values.primaryMobile}
                  onChange={value => {
                    setFieldValue('primaryMobile', value);
                  }}
                />
                <InputErrorMessage
                  errors={errors.primaryMobile}
                  touched={touched.primaryMobile}
                />
              </Column>
            </Column.Group>
          )}
        </React.Fragment>
      )}
    />
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={4}>
        <Label className="is-flex">Description &nbsp;</Label>
        <TextArea
          // label="Service Type Description"
          // id="AddServiceType_ServiceTypeDescription"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          // optionalText=""
          errors={errors.description}
          touched={touched.description}
          disableToastMessage
        />
      </Column>
      <Column size={4}>
        <Label className="is-flex">Food Delivery Description &nbsp;</Label>

        <TextArea
          // label="Service Type Description"
          // id="AddServiceType_ServiceTypeDescription"
          name="food_delivery_description"
          value={values.food_delivery_description}
          onChange={handleChange}
          onBlur={handleBlur}
          // optionalText=""
          errors={errors.food_delivery_description}
          touched={touched.food_delivery_description}
          disableToastMessage
        />
      </Column>

      {/* <Column size={4}>
        <Label className="is-flex">Footer Text &nbsp;</Label>

        <TextArea
          // label="Service Type Description"
          // id="AddServiceType_ServiceTypeDescription"
          name="footer_text"
          value={values.footer_text}
          onChange={handleChange}
          onBlur={handleBlur}
          // optionalText=""
          errors={errors.footer_text}
          touched={touched.footer_text}
          disableToastMessage
        />
      </Column> */}
    </Column.Group>
  </Container>
);
export default Contacts;
