import gql from 'graphql-tag';
import client from '../../../../../utils/apolloClient';

const searchMenuFeesQuery = gql`
  query searchMenuFeesQuery($menuId: String, $userId: String) {
    search_menu_fees(input: { filter: { menu_filter: { menu_id: $menuId } } }) {
      menu_fees {
        user_id
        place_id
        menu_fee_id
        menu_id
        display_name
        internal_name
        is_editable
        fee_type
        value
        payment_method
        period
        start_date
        start_time
        end_date
        end_time
        status
        days {
          day
          is_active
        }
        tax
        tax_description
        tax_id
        tax_value
      }
    }

    fetch_tax(input: { user_id: $userId }) {
      tax_id
      status
      name
      value
    }
  }
`;

export const fetchMenuFees = ({ menuId, userId }) =>
  new Promise(resolve => {
    client.clientPrivate
      .query({
        query: searchMenuFeesQuery,
        variables: {
          menuId,
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          Array.isArray(data?.search_menu_fees?.menu_fees) &&
          data.fetch_tax
        ) {
          resolve({
            menuFees: data.search_menu_fees.menu_fees,
            taxes: data.fetch_tax,
          });
        } else if (Array.isArray(data.fetch_tax)) {
          resolve({
            menuFees: [],
            taxes: data.fetch_tax,
          });
        } else {
          resolve({
            menuFees: [],
            taxes: [],
          });
        }
      })
      .catch(error => {
        console.log(error);
        resolve([]);
      });
  });

export default {};
