import React from 'react';
import styled from 'styled-components';

import uuidv4 from 'uuid/v4';

import List from './List';
import AddEditCustomHour from './AddEditCustomHour';
import { useTableSettingCustomHours } from '../helpers';

const Container = styled.div`
  padding: 0rem 2rem;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const Middle = styled.div`
  padding: 1rem 0rem;
`;

const ButtonText = styled.p`
  font-weight: 600;
  font-size: 12px;
`;

const Button = styled.button`
  &&& {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 35%;
    height: 5vh;
  }
`;

const AddEditCustomHours = ({ placeId, serviceTypeSettingId }) => {
  const [
    customHours,
    customHoursLoading,
    refetchCustomHours,
  ] = useTableSettingCustomHours({
    placeId,
  });

  const holidays = customHours.map(ele => ({
    ...ele,
    custom_hour_id: uuidv4(),
  }));

  const holidayLoading = customHoursLoading;

  const [isActive, setIsActive] = React.useState(false);
  const [customHour, setCustomHour] = React.useState();

  return (
    <>
      {isActive && (
        <AddEditCustomHour
          onHandleClose={() => {
            setCustomHour();
            setIsActive(false);
          }}
          isActive={isActive}
          fetchHours={refetchCustomHours}
          customHours={holidays}
          customHour={customHour}
          serviceTypeSettingId={serviceTypeSettingId}
          placeId={placeId}
        />
      )}

      <Container>
        <FlexRow>
          <Button
            className="button is-primary"
            onClick={() => setIsActive(true)}
            type="button"
          >
            <ButtonText>ADD A NEW DATE</ButtonText>
          </Button>
        </FlexRow>
        <Middle>
          <p className="has-text-weight-semibold is-size-2">
            Holiday or Custom hours
          </p>
          <br />
          <p>
            Add a date and set your menu hours if you plan to close for a
            holiday, stay open for a limited hours or even to stay open for
            longer.
          </p>
        </Middle>
      </Container>

      <List
        holidays={holidays}
        holidayLoading={holidayLoading}
        customHoursFromMenu={customHours}
        fetchHours={refetchCustomHours}
        onHandleUpdate={hours => {
          setCustomHour(hours);
          setIsActive(true);
        }}
      />
    </>
  );
};

export default AddEditCustomHours;
