/* eslint-disable max-len */
import React from 'react';

const Ballon = ({ width = '20', height = '20', onClick = () => {} }) => (
  <span style={{ cursor: 'pointer' }} onClick={onClick}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6971_1255)">
        <path
          d="M11.6667 6.66667C11.6667 6.22464 11.4911 5.80072 11.1785 5.48816C10.866 5.17559 10.442 5 10 5"
          stroke="black"
          strokeOpacity="0.54"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 6.66699C5 5.34091 5.52678 4.06914 6.46447 3.13146C7.40215 2.19378 8.67392 1.66699 10 1.66699C11.3261 1.66699 12.5979 2.19378 13.5355 3.13146C14.4732 4.06914 15 5.34091 15 6.66699C15 10.8087 12.7617 14.167 10 14.167C7.23833 14.167 5 10.8087 5 6.66699Z"
          fill="#EE2A7B"
          stroke="#EE2A7B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99984 14.167V15.0003C9.99984 15.4424 9.82424 15.8663 9.51168 16.1788C9.19912 16.4914 8.7752 16.667 8.33317 16.667H5.83317C5.39114 16.667 4.96722 16.8426 4.65466 17.1551C4.3421 17.4677 4.1665 17.8916 4.1665 18.3337"
          stroke="#EE2A7B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6971_1255">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default Ballon;
