import React, { useState } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import * as yup from 'yup';
import { forEach, omit, isNull, first, isNil } from 'lodash';
import moment from 'moment';
import { Tab } from 'rbx';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';

import ClaimPlace from './ClaimPlace';
import PlaceInformation from './PlaceInformation';
import PlaceDetails from './PlaceDetails';

import PlaceHoursNew from './PlaceHoursNew';
import PlaceImages from './PlaceImages';
import Header from './Header';
import Footer from './Footer';
import Payouts from './Payouts';
import { Box, Tabs } from '../../../components/elements';

import {
  defaultHours,
  addIdInputHours,
  parseFinalInputHours,
} from '../../MenuManagement/Menu/helper';
import {
  placeBusinessHoursHandler,
  menuHourSorter,
} from '../../../utils/helpers';

import UserRoles from './UserRoles';

const tabs = [
  {
    key: 'place-information',
    name: 'Place Information',
    index: 0,
  },
  {
    key: 'place-details',
    name: 'Place details',
    index: 1,
  },
  {
    key: 'place-hours',
    name: 'Place hours',
    index: 2,
  },
  {
    key: 'place-images',
    name: 'Place images',
    index: 3,
  },
];

const Wrapper = styled.div`
  padding: 1rem;
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    values,
    claim,
    handleSubmit,
    loading,
    isClaimed,
    dirty,
    errors,
    isSubmitting,
  } = props;

  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  React.useEffect(() => {
    if (isSubmitting) {
      toast.error(Object.values(errors)[0]);
    }
  }, [isSubmitting]);

  return (
    <React.Fragment>
      <Header
        prev={prev}
        next={next}
        activeTab={activeTab}
        loading={loading}
        handleSubmit={handleSubmit}
        placeName={values.name}
        status={values.status}
        values={values}
        claimStatus={values.claim_status}
        dirty={dirty}
      />
      {/* {props.errors && !isEmpty(props.errors) && (
        <GlobalErrors errors={props.errors} />
      )} */}
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.index}
              key={tab.key}
              onClick={() => setActiveTab(tab.index)}
            >
              {tab.name}
            </Tab>
          ))}
          {props.place && (
            <Tab active={activeTab === 4} onClick={() => setActiveTab(4)}>
              Payouts
            </Tab>
          )}
          {isClaimed && (
            <Tab active={activeTab === 5} onClick={() => setActiveTab(5)}>
              Claim Details
            </Tab>
          )}
          {isClaimed && (
            <Tab active={activeTab === 6} onClick={() => setActiveTab(6)}>
              Admins
            </Tab>
          )}
        </Tabs>
        <Wrapper>
          {activeTab === 0 && (
            <PlaceInformation
              props={props}
              next={() => setActiveTab('place-details')}
            />
          )}
          {activeTab === 1 && (
            <PlaceDetails {...props} next={() => setActiveTab('place-hours')} />
          )}
          {activeTab === 2 && (
            <PlaceHoursNew
              action={['update_place', 'create_place']}
              {...props}
              next={() => setActiveTab('place-images')}
            />
          )}
          {activeTab === 3 && <PlaceImages {...props} />}
          {props.place && activeTab === 4 && <Payouts {...props} />}
          {isClaimed && (
            <React.Fragment>
              {activeTab === 5 && <ClaimPlace {...props} claim={claim} />}
            </React.Fragment>
          )}
          {props.place && activeTab === 6 && <UserRoles {...props} />}
        </Wrapper>
      </Box>
      <hr />
      <Footer
        loading={loading}
        handleSubmit={handleSubmit}
        prev={prev}
        next={next}
        activeTab={activeTab}
        dirty={dirty}
      />
    </React.Fragment>
  );
};

const AddPlaceForm = withFormik({
  mapPropsToValues: ({ place, userId, claim, uploadedImages }) => {
    const contact =
      place && !isNull(place.contact)
        ? place.contact.filter(item => item.is_primary === false)
        : [];

    const primaryEmail =
      place && !isNull(place.contact)
        ? place.contact.filter(item => item.type === 'email_primary')
        : [];
    const primaryMobile =
      place && !isNull(place.contact)
        ? place.contact.filter(item => item.type === 'phone_primary')
        : [];

    const businessSite =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'website',
          )
        : [];

    return {
      sales_user:
        place &&
        !isNil(place?.sales_member_info) &&
        !isNil(place?.sales_member_info?.sales_member_email) &&
        !isNil(place?.sales_member_info?.sales_member_name)
          ? {
              ...place?.sales_member_info,
              user_id: place?.sales_member_info?.sales_member_id,
              display_name: place?.sales_member_info?.sales_member_name,
              email: place?.sales_member_info?.sales_member_email,
            }
          : null,
      is_test_place:
        place && !isNil(place?.is_test_place) ? place.is_test_place : false,
      account_id:
        place && !isNil(place?.payment_account?.account_id)
          ? place.payment_account.account_id
          : null,
      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      displayEmail:
        primaryEmail.length !== 0 ? first(primaryEmail).display : false,
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      displayMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).display : false,
      businessSite: businessSite.length !== 0 ? first(businessSite).value : '',
      displayBusiness:
        businessSite.length !== 0 ? first(businessSite).display : false,
      user_id: userId,
      place_id: place ? place.place_id : uuidv4(),
      name: place ? place.name : '',
      slug: place ? place.slug : '',
      status: place ? place.status : 'ACTIVE',
      contact,
      social:
        place && !isNull(place.social) ? removeTypename(place.social) : [],
      listing_status: place ? place.listing_status : 'APPROVED',
      claim_status: place ? place.claim_status : 'UNCLAIMED',

      featured: place && !isNull(place.featured) ? place.featured : false,
      event_business:
        place && !isNull(place.event_business) ? place.event_business : false,
      item_review_place:
        place && !isNull(place.item_review_place)
          ? place.item_review_place
          : false,
      price_range: place ? parseInt(place.price_range, 10) : '',
      business_type:
        place && !isNull(place.business_type)
          ? removeTypename(place.business_type)
          : [],
      service_type:
        place && !isNull(place.service_type)
          ? removeTypename(place.service_type)
          : [],
      dietary_information:
        place && !isNull(place.dietary_information)
          ? removeTypename(place.dietary_information)
          : [],
      service_category:
        place && !isNull(place.service_category)
          ? removeTypename(place.service_category)
          : [],
      cuisine_style:
        place && !isNull(place.cuisine_style)
          ? removeTypename(place.cuisine_style)
          : [],
      item_type:
        place && !isNull(place.item_type)
          ? removeTypename(place.item_type)
          : [],
      feature:
        place && !isNull(place.feature) ? removeTypename(place.feature) : [],
      dining_style:
        place && !isNull(place.dining_style)
          ? removeTypename(place.dining_style)
          : [],
      parking_detail:
        place && !isNull(place.parking_detail)
          ? removeTypename(place.parking_detail)
          : [],
      public_transport:
        place && !isNull(place.public_transport)
          ? removeTypename(place.public_transport)
          : [],
      noise_level:
        place && !isNull(place.noise_level)
          ? removeTypename(place.noise_level)
          : [],
      dress_code:
        place && !isNull(place.dress_code)
          ? removeTypename(place.dress_code)
          : [],
      ambience:
        place && !isNull(place.ambience) ? removeTypename(place.ambience) : [],
      good_for:
        place && !isNull(place.good_for) ? removeTypename(place.good_for) : [],
      keywords: place && !isNull(place.keywords) ? place.keywords : [],
      tagline: place && !isNull(place.tagline) ? place.tagline : '',
      description: place && !isNull(place.description) ? place.description : '',
      tag: place && !isNull(place.tag) ? place.tag : [],
      image: [
        {
          url: '',
          preview: null,
          tooltip: '',
          link: '',
          tag: [],
          uploadingStatus: 'pending',
        },
      ],
      uploadedImages: uploadedImages || [],
      default_image_url: place ? place.default_image_url : '',
      current_default_image_url: place ? place.default_image_url : '',
      address_line_1:
        place && !isNull(place.address_line_1) ? place.address_line_1 : '',
      address_line_2:
        place && !isNull(place.address_line_2) ? place.address_line_2 : '',
      city: place && !isNull(place.city) ? place.city : '',
      state: place && !isNull(place.state) ? place.state : '',
      country: place && !isNull(place.country) ? place.country : '',
      post_code: place && !isNull(place.post_code) ? place.post_code : '',
      latitude: place && !isNull(place.latitude) ? place.latitude : '',
      longitude: place && !isNull(place.longitude) ? place.longitude : '',
      location: place && !isNull(place.location) ? place.location : '',
      timezone: place && !isNull(place.timezone) ? place.timezone : '+10',
      business_hour:
        place &&
        !isNull(place.business_hour) &&
        place.business_hour.length !== 0
          ? placeBusinessHoursHandler(addIdInputHours(place.business_hour))
          : defaultHours,

      listing_audit: {
        listing_approved_by:
          place && !isNull(place.listing_audit.listing_approved_by)
            ? place.listing_audit.listing_approved_by
            : '',
        listing_approved_at:
          place && !isNull(place.listing_audit.listing_approved_at)
            ? moment(place.listing_audit.listing_approved_at).format(
                'MMMM Do YYYY, h:mm:ss a',
              )
            : '',
        listing_contact_user_id:
          place && !isNull(place.listing_audit.listing_contact_user_id)
            ? place.listing_audit.listing_contact_user_id
            : '',
        listing_contact_name:
          place && !isNull(place.listing_audit.listing_contact_name)
            ? place.listing_audit.listing_contact_name
            : '',
        listing_contact_phone:
          place && !isNull(place.listing_audit.listing_contact_phone)
            ? place.listing_audit.listing_contact_phone
            : '',
        listing_contact_email:
          place && !isNull(place.listing_audit.listing_contact_email)
            ? place.listing_audit.listing_contact_email
            : '',
        listing_contact_role:
          place && !isNull(place.listing_audit.listing_contact_role)
            ? place.listing_audit.listing_contact_role
            : '',
      },

      claim: {
        contact_full_name:
          claim && !isNull(claim.contact_full_name)
            ? claim.contact_full_name
            : '',
        contact_phone:
          claim && !isNull(claim.contact_phone) ? claim.contact_phone : '',
        contact_email:
          claim && !isNull(claim.contact_email) ? claim.contact_email : '',
        contact_role:
          claim && !isNull(claim.contact_role) ? claim.contact_role : '',
        contact_business_url:
          claim && !isNull(claim.contact_business_url)
            ? claim.contact_business_url
            : '',
        contact_linked_in_url:
          claim && !isNull(claim.contact_linked_in_url)
            ? claim.contact_linked_in_url
            : '',
        contact_facebook_url:
          claim && !isNull(claim.contact_facebook_url)
            ? claim.contact_facebook_url
            : '',
        service_type:
          claim && !isNull(claim.service_type)
            ? removeTypename(claim.service_type)
            : [],
        agree_terms_and_conditions:
          claim && !isNull(claim.agree_terms_and_conditions)
            ? claim.agree_terms_and_conditions
            : false,
        verification_mode:
          claim && !isNull(claim.verification_mode)
            ? claim.verification_mode
            : '',
        verification_note:
          claim && !isNull(claim.verification_note)
            ? claim.verification_note
            : [],

        place_claimant_audit:
          claim && !isNull(claim.place_claimant_audit)
            ? {
                claim_approved_at:
                  claim.place_claimant_audit &&
                  !isNull(claim.place_claimant_audit.claim_approved_at)
                    ? moment(
                        claim.place_claimant_audit.claim_approved_at,
                      ).format('MMMM Do YYYY, h:mm:ss a')
                    : '',
                claim_approved_by:
                  claim.place_claimant_audit &&
                  !isNull(claim.place_claimant_audit.claim_approved_by)
                    ? claim.place_claimant_audit.claim_approved_by
                    : '',
              }
            : { claim_approved_at: '', claim_approved_by: '' },
      },
    };
  },
  validate: values => {
    const errors = {};
    if (values.primaryMobile && values.primaryMobile.length !== 0) {
      const check = isPossiblePhoneNumber(values.primaryMobile);
      if (!check) {
        errors.primaryMobile = 'Please enter valid mobile number';
      }
    }

    return errors;
  },
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 75 Characters!')
      .required('Place Name is required!'),
    primaryEmail: yup.string().email('Please enter valid email address'),

    business_type: yup
      .array()
      .min(1)
      .required('Business Type is required'),
    service_category: yup
      .array()
      .min(1)
      .required('Service Category is required'),
    cuisine_style: yup
      .array()
      .min(1)
      .required('Cuisine Style is required'),
    businessSite: yup.string().url('Please enter valid url'),
    keywords: yup.array(),
    tagline: yup
      .string()
      .min(10, 'At least 10 Character Required!')
      .max(80, 'The tagline should not have more than 80 Characters!'),
    description: yup
      .string()
      .min(10, 'At least 10 Characters are Required!')
      .max(8000, 'Description cannot be greater than 8000 Characters!'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('Suburb / City is required'),
    post_code: yup.string().required('Post Code is required'),
    // timezone: yup.string().required('Timezone is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    latitude: yup.string().required('Latitude is required'),
    longitude: yup.string().required('Longitude is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit({
        ...values,
        business_hour: menuHourSorter(
          parseFinalInputHours(values.business_hour),
        ),
      });
      setSubmitting(false);
    }, 300);
  },

  displayName: 'AddPlaceForm',
})(Form);

export default AddPlaceForm;
