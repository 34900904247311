import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Details from './Details';
import client from '../../../utils/apolloClient';
import { Loading, Message } from '../../../components/elements';

const searchTaxQuery = gql`
  query fetchTaxQuery($userId: String) {
    fetch_tax(input: { user_id: $userId }) {
      tax_id
      status
      name
      value
    }
  }
`;

const Taxes = () => {
  const { userId } = useStoreState(state => state.auth);

  const { data, loading, error, refetch } = useQuery(searchTaxQuery, {
    variables: {
      userId,
    },
    client: client.clientPrivate,
  });

  console.log('data', data, loading);

  return (
    <Layout>
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Details taxValues={data.fetch_tax} refetch={refetch} />
      )}
    </Layout>
  );
};

export default Taxes;
