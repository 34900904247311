import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({
  history,
  loading,
  isEdit,
  children,
  next,
  prev,
  activeTab,
  itemName,
  handleSubmit,
  location,
  setSaveAndAddMore,
  saveAndAddMore,
  dontDisplayButtons,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">
          {isEdit ? 'Edit Menu Item' : 'Add Menu Item'}
        </Title>
      </Level.Item>

      <Level.Item align="right">
        {!dontDisplayButtons && (
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-grey">
              <span className="has-text-weight-semibold">Cancel</span>
            </a>
          </Level.Item>
        )}

        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}

        {!isEdit && !dontDisplayButtons && (
          <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action={`${
                  location.pathname === '/update-menu-item'
                    ? 'update_menu_item'
                    : 'create_menu_item'
                }`}
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <Level.Item>
                    <button
                      type="button"
                      className={`button is-primary ${saveAndAddMore &&
                        loading &&
                        'is-loading'}`}
                      onClick={() => {
                        if (!saveAndAddMore && loading) {
                          return;
                        }
                        setSaveAndAddMore(true);
                        handleSubmit();
                      }}
                    >
                      <span className="has-text-weight-semibold">
                        Submit And Add More
                      </span>
                    </button>
                  </Level.Item>
                )}
              />
            )}
          </ACLSConsumer>
        )}
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/update-menu-item'
                  ? 'update_menu_item'
                  : 'create_menu_item'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    type="button"
                    className={`button is-primary ${!saveAndAddMore &&
                      loading &&
                      'is-loading'}`}
                    onClick={
                      isEdit
                        ? handleSubmit
                        : () => {
                            if (saveAndAddMore && loading) {
                              return;
                            }
                            setSaveAndAddMore(false);
                            handleSubmit();
                          }
                    }
                  >
                    <span className="has-text-weight-semibold">Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
        {activeTab !== 3 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
    <p className="is-size-2">{itemName}</p>
    {children}
  </Wrapper>
);

export default withRouter(Header);
