import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';

import { useTableBookingsDetails } from './helpers';

import Ballon from './Ballon';

import Birthday from './Birthday';
import { useUpdateTableBooking } from '../AddModifyBooking/helpers';

const TableItem = styled.div`
  border: 1px solid #e2e2e2;
  font-size: 12px;
  color: black;
  padding: 10px;
  text-align: center;
  background-color: ${({ isAlternate }) =>
    isAlternate ? '#EEEEEE' : '#FFFFFF'};
`;

const statusColorDict = {
  UPCOMING: '#FF9900',
  CHECKEDIN: 'green',
  SEATED: '#00AEEF',
  WAITLISTED: '#EE2A7B',
  'NO SHOW': 'red',
  RECEIVED: 'green',
  CONFIRMED: '#00AEEF',
  CANCELLED: 'red',
};

const statusStyles = color => ({
  color: color ? statusColorDict[color] : '#505050',
  fontWeight: '600',
});

const TableItems = ({ tab, ele, placeId, history, idx }) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);

  const [
    ,
    tableBookingDetails,
    refetchTableBookingsDetails,
  ] = useTableBookingsDetails({
    adminUserId,
    tableBookingId: ele.table_booking_id,
    placeId,
  });

  const shldShowConfirmButton =
    (tab === 0 || tab === 1) &&
    tableBookingDetails?.booking_status === 'RECEIVED';

  const shldShowSeatButton =
    (tab === 0 || tab === 1 || tab === 2) &&
    tableBookingDetails?.booking_status === 'CONFIRMED' &&
    tableBookingDetails?.guest_status === 'UPCOMING';

  const shldShowGuestStatus = tableBookingDetails?.guest_status !== 'UPCOMING';

  const [, updateTableBooking, , ,] = useUpdateTableBooking();

  const onChangeBookingStatus = async bookingStatus => {
    await updateTableBooking({
      user_id: adminUserId,
      table_booking_id: tableBookingDetails.table_booking_id,
      booking_status: bookingStatus,
    });

    await refetchTableBookingsDetails();
  };

  const onChangeGuestStatus = async guestStatus => {
    await updateTableBooking({
      user_id: adminUserId,
      table_booking_id: tableBookingDetails.table_booking_id,
      guest_status: guestStatus,
    });

    await refetchTableBookingsDetails();
  };

  return (
    <>
      <TableItem isAlternate={idx % 2 === 1} style={{ fontSize: '12px' }}>
        <div className="is-flex-direction-column">
          <div className="has-text-left" style={{ color: '#FF9900' }}>
            {ele.booking_reference}
          </div>
          <div className="has-text-left">{ele.booking_time}</div>
          <div className="has-text-left">{ele.booking_date}</div>
          {Array.isArray(ele.tags) &&
            !!ele.tags.find(ele2 => ele2 === 'Anniversary') && (
              <div className="has-text-left">
                <Ballon />
              </div>
            )}
          {Array.isArray(ele.tags) &&
            !!ele.tags.find(ele2 => ele2 === 'Birthday') && (
              <div className="has-text-left">
                <Birthday />
              </div>
            )}
        </div>
      </TableItem>
      <TableItem isAlternate={idx % 2 === 1} style={{ fontSize: '12px' }}>
        <div className="is-flex-direction-column">
          <div>{ele.guest_count}</div>
        </div>
      </TableItem>
      <TableItem
        isAlternate={idx % 2 === 1}
        style={{ fontSize: '12px', cursor: 'pointer' }}
        onClick={() =>
          history.push(`/add-modify-booking/${placeId}/${ele.table_booking_id}`)
        }
      >
        <div className="is-flex-direction-column">
          <div className="has-text-left">
            {ele.contact.first_name} {ele.contact.last_name}
          </div>
          {tableBookingDetails?.guest_notes && (
            <div
              className="has-text-left"
              style={{ color: '#505050', fontWeight: '700' }}
            >
              {tableBookingDetails.guest_notes}
            </div>
          )}
          {tableBookingDetails?.restaurant_notes && (
            <div
              className="has-text-left"
              style={{ color: '#EE2A7B', fontWeight: '700' }}
            >
              {tableBookingDetails.restaurant_notes}
            </div>
          )}
        </div>
      </TableItem>
      <TableItem isAlternate={idx % 2 === 1} style={{ fontSize: '12px' }}>
        <div className="is-flex-direction-column">
          <div className="has-text-left">
            {tableBookingDetails?.table_number}
          </div>
        </div>
      </TableItem>
      <TableItem isAlternate={idx % 2 === 1} style={{ fontSize: '12px' }}>
        <div className="is-flex-direction-column">
          <div className="has-text-center" style={{ marginBottom: '1.5rem' }}>
            <a href={`tel:+${ele.contact.contact_phone}`}>
              {ele.contact.contact_phone}
            </a>
          </div>
          {shldShowGuestStatus && (
            <div
              className="has-text-center"
              style={{
                ...statusStyles(tableBookingDetails?.guest_status),
                marginBottom: '1.5rem',
              }}
            >
              {tableBookingDetails?.guest_status}
            </div>
          )}
          {shldShowSeatButton && (
            <button
              type="button"
              className="button is-rounded is-primary"
              onClick={() => {
                swal({
                  text: 'Would you like to SEAT this table booking?',
                  buttons: ['Cancel', 'OK'],
                }).then(res => {
                  if (res) {
                    onChangeGuestStatus('SEATED');
                  }
                });
              }}
              style={{
                marginBottom: '1.5rem',
                width: '4rem',
                fontSize: '0.75rem',
              }}
            >
              Seat
            </button>
          )}
        </div>
      </TableItem>
      <TableItem isAlternate={idx % 2 === 1} style={{ fontSize: '12px' }}>
        <div className="is-flex-direction-column">
          <div
            style={{
              ...statusStyles(tableBookingDetails?.booking_status),
              marginBottom: '1.5rem',
            }}
          >
            {tableBookingDetails?.booking_status}
          </div>
          {shldShowConfirmButton && (
            <button
              type="button"
              className="button is-rounded is-primary"
              onClick={() => {
                swal({
                  text: 'Would you like to CONFIRM this table booking?',
                  buttons: ['Cancel', 'OK'],
                }).then(res => {
                  if (res) {
                    onChangeBookingStatus('CONFIRMED');
                  }
                });
              }}
              style={{
                marginBottom: '1.5rem',
                width: '4rem',
                fontSize: '0.75rem',
              }}
            >
              Confirm
            </button>
          )}
          <button
            type="button"
            className="button is-rounded is-primary is-outlined"
            onClick={() => {
              history.push(
                `/add-modify-booking/${placeId}/${ele.table_booking_id}`,
              );
            }}
            style={{
              marginBottom: '1.5rem',
              width: '4rem',
              fontSize: '0.75rem',
            }}
          >
            Edit
          </button>
        </div>
      </TableItem>
    </>
  );
};

export default TableItems;
