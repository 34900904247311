import React from 'react';
import gql from 'graphql-tag';

import client from '../../../../utils/apolloClient';

const userAccessQuery = gql`
  query userAccessQuery($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      access {
        place_id
      }
    }
  }
`;

export const fetchUserAccess = async ({ userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: userAccessQuery,
      variables: {
        userId,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data?.fetch_user) {
      return data.data.fetch_user;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useUserAccessData = ({ userId }) => {
  const [userAccess, setUserAccess] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      const res = await fetchUserAccess({ userId });
      if (res) {
        setUserAccess(res);
      } else {
        // pass
      }
    };

    fetchFunc();
  }, [userId]);

  return userAccess;
};
